import React from "react";
import "./SlihomeView.css";
import { Container, Row, Col, OverlayTrigger, Tooltip, Button, Spinner } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { SlihomeAPIClient } from "../../Models/SlihomeAPI";
import { store } from "react-notifications-component";
import SlihomeReviewList from "./SlihomeReviewList";
import Scrollbars from "react-custom-scrollbars";

class SlihomeView extends React.Component {
	constructor(props) {
		super(props);
		this.images = [];
		if (this.props.data.Pictures) {
			this.props.data.Pictures.forEach(ele => {
				this.images.push({
					original: ele,
					thumbnail: ele,
				})
			})
		}
		this.state = {
			loadLessorInfo: false,
			lessorInfo: undefined
		}
	}

	getLessorInfo() {
		this.setState({
			loadLessorInfo: true
		});
		SlihomeAPIClient.getPublicUserDetail({
			targetUsername: this.props.data.PK.split("#")[2]
		}).then((response) => {
			let data = response.data;
			this.setState({
				lessorInfo: {
					phoneNumber: data.Username,
					name: data.UserAttributes.find(ele => ele.Name === "name") ? data.UserAttributes.find(ele => ele.Name === "name").Value : undefined,
					email: data.UserAttributes.find(ele => ele.Name === "email") ? data.UserAttributes.find(ele => ele.Name === "email").Value : undefined,
				}
			})
		}).catch((err) => {
			console.error(err.response);
			store.addNotification({
				title: err.response.data ? err.response.data.message || "Không lấy được số điện thoại" : "Không lấy được số điện thoại",
				message: "Xảy ra lỗi trong quá trình lấy số điện thoại của chủ trọ, vui lòng thử lại. Nếu lỗi này xảy ra liên tiếp, vui lòng liên hệ quản trị viên thông qua fanpage",
				type: "danger",
				insert: "top",
				container: "bottom-left",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true
				}
			});
		}).finally(() => {
			this.setState({
				loadLessorInfo: false
			});
		})
	}

	render() {
		if (this.props.data) {
			return (
				<Container fluid id="view-container">
					<Row style={{ display: "flex" }}>
						<Col lg={4}>
							{
								this.images.length === 0
									?
									<div style={{ width: "100%", height: "100%" }}>
										<img src="/icon/search-idle.svg" alt="Không có hình ảnh" />
										Không có hình ảnh
									</div>
									:
									<ImageGallery items={this.images} showIndex />
							}
						</Col>
						<Col lg={4} className='house-information'>
							<Scrollbars>
								<Row className='card-header'>
									<Col xs={7}>
										<span className="card-title">{this.props.data.AdvertiseTitle}</span>
									</Col>
									<Col xs={5}>
										<span className="card-text card-allowanceInfo card-right">
											{
												this.props.data.MaxSlots > 0 ?
													<div className="gradient-text card-currentSlot">{this.props.data.MaxSlots - this.props.data.CurrentSlot}</div>
													: ""
											}
											{checkGenderAllowance(this.props.data.GenderAllowance)}
										</span>
									</Col>
								</Row>
								<Row className="card-body card-info">
									<Col>
										<div className="card-cost gradient-text"> {numberWithCommas(this.props.data.Cost)} VND</div>
									</Col>
									{/* <div className="col-xl-5">
										<div className="price-container original-price"> <span id="original-price">0</span> VND </div>
									</div> */}
								</Row>
								<Row>
									<Col>
										<OverlayTrigger
											placement="left"
											overlay={<Tooltip>Mô tả</Tooltip>}
										>
											<div className="card-text card-info">
												<img src="/icon/paper-color.svg" className="view-icon" alt="paper-icon" />
												<span className="card-text">Mô tả: {this.props.data.Description}</span>
											</div>
										</OverlayTrigger>
									</Col>
								</Row>
								<Row>
									<Col xs={8}>
										<OverlayTrigger
											placement="left"
											overlay={<Tooltip>Địa chỉ chính xác có thể sai lệch so với địa chỉ đánh dấu trên bản đồ</Tooltip>}
										>
											<div className="card-text card-info">
												<img src="/icon/path-931.svg" className="view-icon" alt="location-icon" />
												<span className="card-text">{this.props.data.Address}</span>
											</div>
										</OverlayTrigger>
									</Col>
									<Col xs={4}>
										<OverlayTrigger
											placement="left"
											overlay={<Tooltip>Kích thước nhà</Tooltip>}
										>
											<div className="card-text card-info card-right">
												<img src="/icon/path-1141.svg" className="view-icon" alt="info-icon" />
												<span className="card-text">{this.props.data.Area} &#13217;</span>
											</div>
										</OverlayTrigger>
									</Col>
								</Row>
								{
									this.props.data.Floor > 0 ?
										<Row>
											<Col>
												<div className="card-text card-info">
													<img src="/icon/home2tang.svg" className="view-icon" alt="floor-icon" />
													<span className="card-text">Tầng: {this.props.data.Floor} </span>
												</div>
											</Col>
										</Row>
										: ""
								}

								<Row className="card-group-title">
									<Col>
										<span>Chi phí phát sinh và thông tin thanh toán</span>
									</Col>
								</Row>
								<Row>
									{
										this.props.data.ElectricCost >= 0 ?
											<Col xs={6}>
												<OverlayTrigger
													placement="left"
													overlay={<Tooltip>Giá tiền điện</Tooltip>}
												>
													<div className="card-text card-info">
														<img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
														<span className="card-text">{numberWithCommas(this.props.data.ElectricCost)} VNĐ / số điện</span>
													</div>
												</OverlayTrigger>
											</Col>
											: ""
									}
									{
										this.props.data.InternetCost >= 0 ?
											<Col xs={6}>
												<OverlayTrigger
													placement="left"
													overlay={<Tooltip>Tiền mạng internet</Tooltip>}
												>
													<div className="card-text card-info card-right">
														<img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
														<span className="card-text">Internet: {numberWithCommas(this.props.data.InternetCost)} VNĐ / tháng</span>
													</div>
												</OverlayTrigger>
											</Col>
											: ""
									}
								</Row>
								<Row>
									{
										this.props.data.WaterCost >= 0 ?
											<Col>
												<OverlayTrigger
													placement="left"
													overlay={<Tooltip>Giá tiền nước</Tooltip>}
												>
													<div className="card-text card-info">
														<img src="/icon/pay-color.svg" className="view-icon" alt="price-icon" />
														<span className="card-text">{numberWithCommas(this.props.data.WaterCost)} VNĐ / số nước</span>
													</div>
												</OverlayTrigger>
											</Col>
											: ""
									}
								</Row>
								<Row>
									<Col xs={12}>
										<div className="card-text card-info">
											<img src="/icon/month-color.svg" className="view-icon" alt="calendar-icon" />
											<span className="card-text">Thanh toán vào {this.props.data.PaymentDate <= 10 ? `mùng ${this.props.data.PaymentDate}` : this.props.data.PaymentDate} hàng tháng</span>
										</div>
									</Col>
								</Row>
								<Row className="card-group-title">
									<Col>
										<span>Chi tiết và thông tin liên hệ</span>
									</Col>
								</Row>
								<Row>
									<Col>
										<OverlayTrigger
											placement="left"
											overlay={<Tooltip>Thông tin thêm</Tooltip>}
										>
											<div className="card-text card-info">
												<span>
													<img src="/icon/note.svg" className="view-icon" alt="exclaimation icon" />
													<span className="card-text">Thông tin thêm: {this.props.data.Note ? this.props.data.Note : "Không có"} </span>
												</span>
											</div>
										</OverlayTrigger>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="card-text card-info">
											<span>
												{this.state.lessorInfo ?
													<div>
														{this.state.lessorInfo.name ?
															<Row>
																<Col>
																	<div className="card-text card-info">
																		<img src="/icon/user.svg" className="view-icon" alt="user-icon" />
																		<span className="card-text">Họ và tên: {this.state.lessorInfo.name} </span>
																	</div>
																</Col>
															</Row>
															: ""
														}
														<Row>
															<Col>
																<div className="card-text card-info">
																	<img src="/icon/telephone.svg" className="view-icon" alt="phone-icon" />
																	<span className="card-text">Số điện thoại: {this.state.lessorInfo.phoneNumber} </span>
																</div>
															</Col>
														</Row>
														{
															this.state.lessorInfo.email ?
																<Row>
																	<Col>
																		<div className="card-text card-info">
																			<img src="/icon/email.svg" className="view-icon" alt="email-icon" />
																			<span className="card-text">Email: {this.state.lessorInfo.email} </span>
																		</div>
																	</Col>
																</Row>
																: ""
														}

													</div>
													:
													this.state.loadLessorInfo ?
														<Row>
															<Col>
																<Spinner animation="border" variant="danger" size="sm" />
															</Col>
														</Row>
														:
														<Row>
															<Col>
																<OverlayTrigger
																	placement="left"
																	overlay={<Tooltip>Bấm vào đây để lấy số điện thoại của chủ trọ</Tooltip>}
																>
																	<Button variant="outline-danger" onClick={this.getLessorInfo.bind(this)} size="sm">Lấy thông tin liên hệ</Button>
																</OverlayTrigger>
															</Col>
														</Row>
												}
											</span>
										</div>
									</Col>
								</Row>
							</Scrollbars>
						</Col>
						<Col lg={4}>
							<Row style={{ height: "100%" }}>
								<Col style={{ height: "100%" }}>
									<SlihomeReviewList roomInfo={this.props.data} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			)
		}
		else {
			return (
				<Container fluid id="view-container">
					<img src="/icon/construction.svg" style={{ height: "300px" }} alt="under construction"></img>
					<span>Không có thông tin</span>
				</Container>
			)
		}
	}
}

export default SlihomeView;

function numberWithCommas(x) {
	if (x && x.toString instanceof Function)
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function checkGenderAllowance(GenderAllowance) {
	if (GenderAllowance === "Male") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nam</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol.svg" className="card-icon" alt="female-deactive" />
				</div>
			</OverlayTrigger>
		)
	}
	else if (GenderAllowance === "Female") {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol.svg" className="card-icon" alt="male-deactive" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>
		)
	}
	else {
		return (
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip>Cả nam và nữ</Tooltip>}
			>
				<div className="allowanceProp genderAllowance">
					<img src="/icon/male-symbol-1.svg" className="card-icon" alt="male-active" />
					<img src="/icon/path-1132.svg" className="card-icon" alt="slash" />
					<img src="/icon/female-symbol-1.svg" className="card-icon" alt="female-active" />
				</div>
			</OverlayTrigger>
		)
	}
}