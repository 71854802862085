import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Grid, CircularProgress, Typography, Tooltip, IconButton, TextField, Divider, Button, MenuItem, Hidden, Tab, Tabs, AppBar } from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { store } from 'react-notifications-component';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { SlihomeAPIClient } from '../Models/SlihomeAPI';
import Avatar from 'react-avatar-edit';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import SlihomeTheme from '../Models/SlihomeMuiTheme';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			style={{ paddingTop: "1rem", width: "100%", height: "100%" }}
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div style={{ width: "100%", height: "100%" }}>
					{children}
				</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
	containerPadding: {
		padding: "1rem",
	},
	editorContainer: {
		display: "none",
		position: 'fixed',
		width: "fit-content",
		height: "fit-content",
		top: "calc(50% + 32px)",
		left: "50%",
		transform: "translate(-50%,-50%)",
		borderRadius: "10px",
		backgroundColor: "#FFFFFF",
		zIndex: 10000,
		boxShadow: "0px 10px 20px -5px rgba(0,0,0,0.75)",
		padding: "20px",
	},
	userInfoContainer: {
		transition: "all 0.2s",
	},
	userInfoAvatarEdit: {
		position: "absolute",
		borderRadius: "50%",
		maxWidth: "40px",
		opacity: 0.6,
		transition: "all 0.2s",
		transform: "translate(-1vw, 1vw)",
		'&:hover': {
			opacity: 1
		},
	},
	userInfoName: {
		fontWeight: 600,
		color: "#484848"
	},
	userInfoEmail: {
		fontWeight: 400,
		color: "#BCBCBC"
	},
	userInfoHeader: {
		fontSize: "1.2rem",
		fontWeight: 600,
		textAlign: "center",
	},
	verifiedMark: {
		height: "1rem",
		border: "2px solid #f02d4e",
		borderRadius: "50%",
		padding: "2px",
		marginLeft: "0.5rem",
	},
	notchedOutline: {
		borderRadius: "0",
		borderTop: "none",
		borderLeft: "none",
		borderRight: "none",
		borderColor: "#f02d4e !important"
	}
});

let userInfoChangedHandler;

export default function SlihomeUserProfile(props) {
	let history = useHistory();
	let location = useLocation();
	const [tabValue, setTabValue] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const [avatarUploading, setAvatarUploading] = React.useState(false);
	const [userInfo, setuserInfo] = React.useState({
		"Username": "",
		"UserAttributes": {},
		"AllowModify": false,
	});
	const [operation, setOperation] = React.useState();
	const [avatarPreview, setAvatarPreview] = React.useState(null);
	const [error, setError] = React.useState(null);
	const [avatarEditorSize, setAvatarEditorSize] = useState(500);

	useEffect(() => {
		function windowResizeHandler() {
			let w = window.innerWidth;
			let h = window.innerHeight;
			if (w * 0.6 < h * 0.7) {
				setAvatarEditorSize(Math.floor(w * 0.6))
			}
			else {
				setAvatarEditorSize(Math.floor(h * 0.6))
			}
		}
		window.addEventListener("resize", windowResizeHandler);
		return () => {
			window.removeEventListener("resize", windowResizeHandler);
		}
	}, [])

	useEffect(() => {
		switch (operation) {
			case "#doi-mat-khau":
				if (!loading) showPasswordEditor();
				break;
			case "#doi-avatar":
				if (!loading) showAvatarEditor();
				break;
			default:
				hidePasswordEditor();
				hideAvatarEditor();
		}
	}, [operation, loading])

	useEffect(() => {
		setOperation(location.hash);
	}, [location.hash])

	const loadUserInfo = useCallback((username = null) => {
		if (localStorage.authInfo) {
			let authInfo = JSON.parse(localStorage.authInfo);
			if (username && username !== authInfo.Username) {
				SlihomeAPIClient.getPublicUserDetail({
					targetUsername: username
				}).then(response => {
					let data = response.data;
					data["UserAttributes"] = UserAttributesToJSON(data["UserAttributes"]);
					data["AllowModify"] = false;
					setuserInfo(data);
				}).catch(err => {
					console.error(err);
					setError(err);
				}).finally(() => {
					setLoading(false);
				})
			}
			else {
				SlihomeAPIClient.getOwnUserDetail()
					.then((res) => {
						let data = res.data;
						data["UserAttributes"] = UserAttributesToJSON(data["UserAttributes"]);
						data["AllowModify"] = true;
						setuserInfo(data);
					}).catch((err) => {
						console.error(JSON.stringify(err));
						setError(err);
					}).finally(() => {
						setLoading(false);
					})
			}
		}
	}, []);

	useEffect(() => {
		loadUserInfo(getQueryParams(window.location.href).username);
		userInfoChangedHandler = () => {
			loadUserInfo();
		}
		window.addEventListener("userInfoChanged", userInfoChangedHandler);
		return () => {
			window.removeEventListener("userInfoChanged", userInfoChangedHandler);
		}
	}, [loadUserInfo])

	const classes = useStyles();

	function onAvatarClose() {
		setAvatarPreview(null)
	}

	function onAvatarCrop(preview) {
		setAvatarPreview(preview)
	}

	function onAvatarBeforeFileLoad(elem) {
		if (elem.target.files[0].size > 6 * 1024 * 1024) {
			alert("Kích thước ảnh quả lớn");
			elem.target.value = "";
		}
		else if (elem.target.files[0].type !== "image/jpeg" && elem.target.files[0].type !== "image/png") {
			alert("Chỉ hỗ trợ jpg, png");
			elem.target.value = "";
		}
	}

	function saveAvatarHandler() {
		if (avatarPreview) {
			setAvatarUploading(true);
			SlihomeAPIClient.uploadImage({
				username: userInfo.Username,
				purpose: "avatar",
				fileName: `${new Date().getTime}.png`,
				content: avatarPreview.split(',')[1],
			}).then(response => {
				let newAvatarURI = response.data.Location;
				if (newAvatarURI) {
					SlihomeAPIClient.updateUserDetails({
						updateAttributes: {
							"picture": newAvatarURI
						}
					}).then(response => {
						//Update Success
						store.addNotification({
							title: "Thành công",
							message: "Thay đổi ảnh đại diện thành công",
							type: "success",
							insert: "top",
							container: "bottom-left",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: true
							}
						});
						window.dispatchEvent(new Event('userInfoChanged'));
						history.replace(location.pathname);
					}).catch(err => {
						// Update avatar error
						store.addNotification({
							title: "Lỗi thay đổi thông tin",
							message: "Xảy ra lỗi trong quá trình thay đổi thông tin cá nhân, vui lòng thử lại sau hoặc liên hệ quản trị viên",
							type: "danger",
							insert: "top",
							container: "bottom-left",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: true
							}
						});
						console.error(err);
					}).finally(() => {
						setAvatarUploading(false);
					})
				}
				else {
					// Upload error no link
					store.addNotification({
						title: "Lỗi tải lên",
						message: "Xảy ra lỗi trong quá trình tải lên ảnh đại diện, vui lòng thử lại sau hoặc liên hệ quản trị viên",
						type: "danger",
						insert: "top",
						container: "bottom-left",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: true
						}
					});
					setAvatarUploading(false);
					console.error(response.data);
				}
			}).catch(err => {
				// Upload error 400
				store.addNotification({
					title: "Lỗi tải lên",
					message: "Xảy ra lỗi trong quá trình tải lên ảnh đại diện, vui lòng thử lại sau hoặc liên hệ quản trị viên",
					type: "danger",
					insert: "top",
					container: "bottom-left",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true
					}
				});
				setAvatarUploading(false);
				console.error(err);
			})
		}
		else {
			// [Construction]] Xóa ảnh
		}
	};

	function handleTabChange(event, newValue) {
		setTabValue(newValue);
	}

	function showAvatarEditor() {
		$('#userInfoContainer').addClass("blur");
		$('#avatarEditorContainer').fadeIn(200);
	}

	function hideAvatarEditor() {
		$('#userInfoContainer').removeClass("blur");
		$('#avatarEditorContainer').fadeOut(200);
	}

	function showPasswordEditor() {
		$('#userInfoContainer').addClass("blur");
		$('#passwordEditorContainer').fadeIn(200);
	}

	function hidePasswordEditor() {
		$('#userInfoContainer').removeClass("blur");
		$('#passwordEditorContainer').fadeOut(200);
	}

	return (
		localStorage.authInfo
			?
			<MuiThemeProvider theme={SlihomeTheme}>
				<Grid id="mainContainer" container spacing={3} justify="flex-start" alignItems="center" direction="column" className={classes.containerPadding}>
					<div className={classes.editorContainer} id="avatarEditorContainer">
						<Grid container justify="center" alignItems="center" direction="column" spacing={3}>
							<Grid item>
								<Avatar imageHeight={avatarEditorSize} label="Chọn ảnh đại diện" onCrop={onAvatarCrop} onClose={onAvatarClose} onBeforeFileLoad={onAvatarBeforeFileLoad}></Avatar>
							</Grid>
							<Grid item container spacing={3} justify="center">
								<Grid item>
									<IconButton aria-label="save" className="gradientBackground" onClick={saveAvatarHandler} disabled={avatarUploading}>
										{
											avatarUploading
												?
												<CircularProgress size={25} style={{ color: "#FFFFFF" }} />
												:
												<DoneRoundedIcon style={{ color: "#FFFFFF" }} />
										}
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton
										aria-label="close"
										className="gradientBackground"
										onClick={() => {
											history.replace(location.pathname);
										}}
										disabled={avatarUploading}>
										<CloseRoundedIcon style={{ color: "#FFFFFF" }} />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</div>
					<div className={classes.editorContainer} id="passwordEditorContainer" style={{ width: "40vw", height: "fit-content", maxHeight: "70vh", overflowY: "auto" }}>
						<SlihomeUserChangePassword />
					</div>
					{
						loading
							?
							<CircularProgress style={{ color: "#f02d4e" }} />
							:
							error
								?
								<Grid item container justify="center" alignItems="center" direction="column" style={{ width: "100%", height: "100%" }}>
									<Grid item>
										<img src="/icon/error.svg" alt="Xảy ra lỗi"></img>
									</Grid>
									<Grid item>
										{error.message}
									</Grid>
								</Grid>
								:
								<Grid item xs id="userInfoContainer" container spacing={1} justify="flex-start" alignItems="center" direction="column" className={classes.userInfoContainer}>
									<Grid item container justify="center">
										<Grid item container alignItems="flex-end" justify="center">
											<Grid item>
												<div className="rounded-circle" style={{ display: "inline-flex", maxWidth: "7vw", maxHeight: "7vw" }}>
													<img src={userInfo.UserAttributes["picture"]} alt="Ảnh đại diện" className="rounded-circle" style={{ width: "100%", height: "100%" }} />
												</div>
												{
													userInfo.AllowModify
														?
														<img src="/icon/editor-icon.svg" alt="Chỉnh sửa ảnh đại diện" className={classes.userInfoAvatarEdit} onClick={() => {
															history.replace(`${location.pathname}#doi-avatar`);
														}} />
														:
														""
												}
											</Grid>
										</Grid>
									</Grid>
									<Grid item container justify="center">
										<Grid item>
											<Typography className={classes.userInfoName}>
												{userInfo.UserAttributes["name"]}
											</Typography>
										</Grid>
									</Grid>
									<Grid item container justify="center">
										<Grid item>
											<Typography className={classes.userInfoEmail}>
												{userInfo.UserAttributes["email"]}
												{

													userInfo.UserAttributes["email_verified"] && JSON.parse(userInfo.UserAttributes["email_verified"])
														?
														<Tooltip title="Đã xác thực">
															<img src="/icon/check-mark.svg" alt="Email Đã xác thực" className={classes.verifiedMark} />
														</Tooltip>
														:
														""
												}
											</Typography>
										</Grid>
									</Grid>
									<Grid item xs container justify="center" alignItems="flex-start" className={classes.containerPadding}>
										<Hidden smDown>
											<Grid item md container alignItems="flex-start" justify="center" style={{ height: "100%" }}>
												<SlihomeUserInfo userInfo={userInfo} classes={classes} />
											</Grid>
											<Divider flexItem orientation="horizontal" style={{ width: "2px", marginLeft: "1rem" }} />
											<Grid item md={6} lg={8} container alignItems="flex-start" justify="center" style={{ height: "100%" }}>
												<Typography className={classes.userInfoHeader}>
													Hoạt động
											</Typography>
											</Grid>
										</Hidden>
										<Hidden mdUp>
											<AppBar position="static" color="default">
												<Tabs
													value={tabValue}
													onChange={handleTabChange}
													indicatorColor="secondary"
													textColor="secondary"
													scrollButtons="auto"
													aria-label="scrollable auto tabs"
													variant="fullWidth"
													centered
												>
													<Tab label="Thông tin cá nhân" />
													<Tab label="Hoạt động" />
												</Tabs>
											</AppBar>
											<TabPanel value={tabValue} index={0}>
												<SlihomeUserInfo userInfo={userInfo} classes={classes} />
											</TabPanel>
											<TabPanel value={tabValue} index={1}>
												<Typography className={classes.userInfoHeader}>
													Hoạt động
											</Typography>
											</TabPanel>
										</Hidden>
									</Grid>
								</Grid>
					}
				</Grid>
			</MuiThemeProvider>
			:
			<Redirect to="/dang-nhap" />
	);
}

class SlihomeUserInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userInfo: props.userInfo,
			editingUserInfo: JSON.parse(JSON.stringify(props.userInfo)), // Copy Info
			profileUpdating: false,
			classes: props.classes
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			userInfo: nextProps.userInfo,
			classes: nextProps.classes
		}
	}

	notifyInvalidField(checkResult = []) {
		checkResult.forEach(ele => {
			switch (ele.type) {
				case "fullName":
					$('#fullNameResultText').html(ele.checkResult);
					$('#fullNameResultHolder').fadeIn().removeClass("transparent");
					break;
				case "email":
					$('#emailResultText').html(ele.checkResult);
					$('#emailResultHolder').fadeIn().removeClass("transparent");
					break;
				case "birthdate":
					$('#birthdateResultText').html(ele.checkResult);
					$('#birthdateResultHolder').fadeIn().removeClass("transparent");
					break;
				default:
			}
		})
	}

	profileEditorHandler = (event) => {
		this.clearResult();
		let attribute = event.target.name;
		let value = event.target.value;
		let currentEditingInfo = this.state.editingUserInfo;
		switch (attribute) {
			case "fullName":
				currentEditingInfo.UserAttributes.name = value;
				break;
			case "gender":
				currentEditingInfo.UserAttributes.gender = value;
				break;
			case "birthdate":
				currentEditingInfo.UserAttributes.birthdate = value;
				break;
			case "address":
				currentEditingInfo.UserAttributes.address = value;
				break;
			case "profile":
				currentEditingInfo.UserAttributes.profile = value;
				break;
			default:
		}
		this.setState(currentEditingInfo);
	}

	checkUserInfoContext(userAttributes){
		let checkResult = [];
		if(userAttributes){
			if (new Date(userAttributes.birthdate) > new Date()) {
				checkResult.push(
					{
						type: "birthdate",
						checkResult: "Ngày sinh không hợp lệ"
					}
				)
			}
		}
		return checkResult;
	}

	clearResult(){
		$('.resultHolder').addClass('transparent');
	}

	saveProfileHandler() {
		let checkResult = this.checkUserInfoContext(this.state.editingUserInfo.UserAttributes);
		if(checkResult.length===0){
			this.clearResult();
			$('#updateProfileResultHolder').addClass("transparent");
			this.setState({
				profileUpdating: true
			})
			let newUserAttributes = {};
			Object.keys(this.state.editingUserInfo.UserAttributes).forEach(attribute => {
				if (this.state.editingUserInfo.UserAttributes[attribute] !== this.state.userInfo.UserAttributes[attribute]) {
					newUserAttributes[attribute] = this.state.editingUserInfo.UserAttributes[attribute];
				}
			})
			SlihomeAPIClient.updateUserDetails({
				updateAttributes: newUserAttributes
			}).then(response => {
				store.addNotification({
					title: "Thành công",
					message: "Thông tin tài khoản đã được cập nhật",
					type: "success",
					insert: "top",
					container: "bottom-left",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true
					}
				});
				window.dispatchEvent(new Event('userInfoChanged'));
			}).catch(err => {
				console.error(err);
				$('#updateProfileResultHolder').removeClass("transparent");
				ReactDOM.render("Hệ thống xảy ra lỗi vui lòng thử lại sau", $('#updateProfileResultText').get(0));
			}).finally(() => {
				this.setState({
					profileUpdating: false
				})
			})
		}
		else {
			this.notifyInvalidField(checkResult);
		}
	}

	render() {
		this.notifyInvalidField(this.checkUserInfoContext(this.state.editingUserInfo.UserAttributes));
		return (
			<MuiThemeProvider theme={SlihomeTheme}>
				<Scrollbars style={{ height: "100%", width: "100%" }}>
					<div style={{ paddingRight: "1rem" }}>
						<Typography className={this.props.classes.userInfoHeader}>
							Thông tin tài khoản
						</Typography>
						<Divider flexItem style={{ height: "2px", marginTop: "0.5rem", marginBottom: "0.5rem" }} />
						<Grid container alignItems="flex-start" justify="center">
							<Grid container alignItems="flex-start" justify="center">
								<Grid item xs style={{ paddingRight: "1rem" }}>
									<TextField
										onChange={this.profileEditorHandler}
										value={this.state.editingUserInfo.UserAttributes.name}
										required
										variant="outlined"
										margin="normal"
										fullWidth
										name="fullName"
										label="Tên hiển thị"
										type="text"
										id="fullName"
										disabled={!this.state.userInfo.AllowModify || this.state.profileUpdating}
										InputProps={{
											classes: {
												notchedOutline: this.props.classes.notchedOutline,
											},
										}}
									/>
									<Grid container justify="space-between">
										<Grid item id="fullNameResultHolder" className="resultHolder transparent" style={{
											display: 'flex',
											alignItems: 'center',
										}}>
											<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
											<span id="fullNameResultText">chưa lưu</span>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm>
									<Tooltip title="Bạn không thể thay đổi email đăng ký">
										<TextField
											onChange={this.profileEditorHandler}
											value={this.state.editingUserInfo.UserAttributes.email}
											variant="outlined"
											margin="normal"
											fullWidth
											name="email"
											label="Email"
											type="text"
											id="email"
											disabled
											InputProps={{
												classes: {
													notchedOutline: this.props.classes.notchedOutline,
												},
											}}
										/>
									</Tooltip>
									<Grid container justify="space-between">
										<Grid item id="emailResultHolder" className="resultHolder transparent" style={{
											display: 'flex',
											alignItems: 'center',
										}}>
											<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
											<span id="emailResultText">chưa lưu</span>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container alignItems="flex-start" justify="center">
								<Grid item xs style={{ paddingRight: "1rem" }}>
									<TextField
										onChange={this.profileEditorHandler}
										value={this.state.editingUserInfo.UserAttributes.gender ? this.state.editingUserInfo.UserAttributes.gender : `Khác`}
										variant="outlined"
										margin="normal"
										fullWidth
										name="gender"
										label="Giới tính"
										select
										id="gender"
										disabled={(!this.state.userInfo.AllowModify && !this.state.userInfo.AllowModify) || this.state.profileUpdating}
										InputProps={{
											classes: {
												notchedOutline: this.props.classes.notchedOutline,
											},
										}}
									>
										<MenuItem value="" disabled>
											Chọn giới tính
										</MenuItem>
										<MenuItem value="male">
											Nam
										</MenuItem>
										<MenuItem value="female">
											Nữ
										</MenuItem>
										<MenuItem value="other">
											Khác
										</MenuItem>
									</TextField>
									<Grid container justify="space-between">
										<Grid item id="genderResultHolder" className="resultHolder transparent" style={{
											display: 'flex',
											alignItems: 'center',
										}}>
											<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
											<span id="genderResultText">chưa lưu</span>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm>
									<TextField
										onChange={this.profileEditorHandler}
										value={this.state.editingUserInfo.UserAttributes.birthdate ? this.state.editingUserInfo.UserAttributes.birthdate : `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1, 2)}-${pad(new Date().getDate(), 2)}`}
										variant="outlined"
										margin="normal"
										fullWidth
										name="birthdate"
										label="Ngày sinh"
										type="date"
										id="birthdate"
										disabled={(!this.state.userInfo.AllowModify && !this.state.userInfo.AllowModify) || this.state.profileUpdating}
										InputProps={{
											classes: {
												notchedOutline: this.props.classes.notchedOutline,
											},
										}}
									/>
									<Grid container justify="space-between">
										<Grid item id="birthdateResultHolder" className="resultHolder transparent" style={{
											display: 'flex',
											alignItems: 'center',
										}}>
											<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
											<span id="birthdateResultText">chưa lưu</span>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TextField
									onChange={this.profileEditorHandler}
									value={this.state.editingUserInfo.UserAttributes.address}
									placeholder="Hãy nhập vào địa chỉ của bạn"
									variant="outlined"
									margin="normal"
									fullWidth
									name="address"
									label="Địa chỉ"
									type="text"
									id="address"
									disabled={!this.state.userInfo.AllowModify || this.state.profileUpdating}
									InputProps={{
										classes: {
											notchedOutline: this.props.classes.notchedOutline,
										},
									}}
								/>
								<Grid container justify="space-between">
									<Grid item id="addressResultHolder" className="resultHolder transparent" style={{
										display: 'flex',
										alignItems: 'center',
									}}>
										<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
										<span id="addressResultText">chưa lưu</span>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TextField
									onChange={this.profileEditorHandler}
									value={this.state.editingUserInfo.UserAttributes.profile}
									multiline
									rows={3}
									rowsMax={3}
									placeholder="Hãy nhập vào một đoạn ngắn mô tả về bạn"
									variant="outlined"
									margin="normal"
									fullWidth
									name="profile"
									label="Mô tả"
									type="text"
									id="profile"
									disabled={!this.state.userInfo.AllowModify || this.state.profileUpdating}
									InputProps={{
										classes: {
											notchedOutline: this.props.classes.notchedOutline,
										},
									}}
								/>
								<Grid container justify="space-between">
									<Grid item id="profileResultHolder" className="resultHolder transparent" style={{
										display: 'flex',
										alignItems: 'center',
									}}>
										<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
										<span id="profileResultText">chưa lưu</span>
									</Grid>
								</Grid>
							</Grid>
							{
								this.state.userInfo.AllowModify
									?
									<Grid item xs={10}>
										<Button
											type="button"
											id="submitButton"
											fullWidth
											variant="contained"
											color="primary"
											className="signin-btn"
											style={{
												"borderRadius": "10rem",
												"height": "3rem",
												"fontWeight": "bolder",
												"fontFamily": "Open Sans",
												"fontSize": "16px"
											}}
											onClick={this.saveProfileHandler.bind(this)}
											disabled={this.state.profileUpdating}
										>
											{!this.state.userInfo.AllowModify || this.state.profileUpdating ? <CircularProgress style={{ color: "#FFFFFF" }} /> : "LƯU"}
										</Button>
									</Grid>
									:
									""
							}
							<Grid item container justify="space-between">
								<Grid item id="updateProfileResultHolder" className="resultHolder transparent" style={{
									display: 'flex',
									alignItems: 'center',
								}}>
									<span id="updateProfileResultText">Chưa lưu</span>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Scrollbars>
			</MuiThemeProvider>
		)
	}
}

function SlihomeUserChangePassword(props) {
	const classes = useStyles();
	const [passwordUpdating, setPasswordUpdating] = React.useState(false);
	let history = useHistory();
	let location = useLocation();

	function savePasswordHandler() {
		clearResult();
		let currentPassword = $('#currentPassword').val();
		let newPassword = $('#newPassword').val();
		let confirmNewPassword = $('#confirmNewPassword').val();
		let checkFormResult = checkContext(currentPassword, newPassword, confirmNewPassword);
		if (checkFormResult.length === 0) {
			setPasswordUpdating(true);
			SlihomeAPIClient.changePasswordRequest({
				oldPass: currentPassword,
				newPass: newPassword,
			}).then(response => {
				store.addNotification({
					title: "Thành công",
					message: "Thay đổi mật khẩu thành công",
					type: "success",
					insert: "top",
					container: "bottom-left",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true
					}
				});
				history.replace(location.pathname);
			}).catch(err => {
				console.error(err);
				store.addNotification({
					title: "Lỗi máy chủ",
					message: "Xảy ra lỗi trong quá trình thay đổi mật khẩu",
					type: "danger",
					insert: "top",
					container: "bottom-left",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true
					}
				});
			}).finally(() => {
				setPasswordUpdating(false);
			})
		}
		else notifyInvalidField(checkFormResult);
	}

	function checkContext(currentPassword, newPassword, confirmNewPassword) {
		let checkResult = [];
		if (!currentPassword) {
			checkResult.push({
				type: "currentPassword",
				checkResult: "Vui lòng nhập mật khẩu cũ"
			})
		}
		if (!newPassword) {
			checkResult.push({
				type: "newPassword",
				checkResult: "Vui lòng nhập mật khẩu mới"
			})
		}
		else if (newPassword.length < 8) {
			checkResult.push({
				type: "newPassword",
				checkResult: "Mật khẩu mới chưa đủ dài (8 ký tự)"
			})
		}
		if (!confirmNewPassword) {
			checkResult.push({
				type: "confirmNewPassword",
				checkResult: "Vui lòng nhập lại mật khẩu mới"
			})
		}
		if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
			checkResult.push({
				type: "confirmNewPassword",
				checkResult: "Mật khẩu không khớp"
			})
		}
		return checkResult;
	}

	function notifyInvalidField(checkResult = []) {
		checkResult.forEach(ele => {
			switch (ele.type) {
				case "currentPassword":
					$('#currentPasswordResultText').html(ele.checkResult);
					$('#currentPasswordResultHolder').fadeIn().removeClass("transparent");
					break;
				case "newPassword":
					$('#newPasswordResultText').html(ele.checkResult);
					$('#newPasswordResultHolder').fadeIn().removeClass("transparent");
					break;
				case "confirmNewPassword":
					$('#confirmNewPasswordResultText').html(ele.checkResult);
					$('#confirmNewPasswordResultHolder').fadeIn().removeClass("transparent");
					break;
				default:
			}
		})
	}

	function clearResult() {
		$('.resultHolder').addClass('transparent');
	}

	return (
		<MuiThemeProvider theme={SlihomeTheme}>
			<Grid container justify="center" alignItems="center">
				<Grid item xs={12}>
					<Typography variant="h5" className="gradient-text" style={{ textAlign: "center" }}>
						Đổi mật khẩu
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						onChange={clearResult}
						variant="outlined"
						margin="normal"
						fullWidth
						name="password"
						label="Mật khẩu cũ"
						type="password"
						id="currentPassword"
						InputProps={{
							classes: {
								notchedOutline: classes.notchedOutline,
							},
						}}
					/>
					<Grid container justify="space-between">
						<Grid item id="currentPasswordResultHolder" className="resultHolder transparent" style={{
							display: 'flex',
							alignItems: 'center',
						}}>
							<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
							<span id="currentPasswordResultText">Bắt buộc</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						onChange={clearResult}
						variant="outlined"
						margin="normal"
						fullWidth
						name="newPassword"
						label="Mật khẩu mới"
						type="password"
						id="newPassword"
						InputProps={{
							classes: {
								notchedOutline: classes.notchedOutline,
							},
						}}
					/>
					<Grid container justify="space-between">
						<Grid item id="newPasswordResultHolder" className="resultHolder transparent" style={{
							display: 'flex',
							alignItems: 'center',
						}}>
							<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
							<span id="newPasswordResultText">Bắt buộc</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						onChange={clearResult}
						variant="outlined"
						margin="normal"
						fullWidth
						name="confirmNewPassword"
						label="Nhập lại mật khẩu mới"
						type="password"
						id="confirmNewPassword"
						InputProps={{
							classes: {
								notchedOutline: classes.notchedOutline,
							},
						}}
					/>
					<Grid container justify="space-between">
						<Grid item id="confirmNewPasswordResultHolder" className="resultHolder transparent" style={{
							display: 'flex',
							alignItems: 'center',
						}}>
							<img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon" />
							<span id="confirmNewPasswordResultText">Bắt buộc</span>
						</Grid>
					</Grid>
				</Grid>
				<Grid container justify="center" alignItems="center" spacing={3} style={{ paddingTop: "1rem" }}>
					<Grid item xs={5}>
						<Button
							type="button"
							id="submitButton"
							fullWidth
							variant="contained"
							color="primary"
							className="submit-btn"
							style={{
								"borderRadius": "10rem",
								"height": "3rem",
								"fontWeight": "bolder",
								"fontFamily": "Open Sans",
								"fontSize": "16px"
							}}
							onClick={savePasswordHandler}
							disabled={passwordUpdating}
						>
							{passwordUpdating ? <CircularProgress style={{ color: "#FFFFFF" }} /> : "ĐỔI MẬT KHẨU"}
						</Button>
					</Grid>
					<Grid item xs={5}>
						<Button
							type="button"
							id="cancelButton"
							fullWidth
							variant="contained"
							className="cancel-btn"
							style={{
								"borderRadius": "10rem",
								"height": "3rem",
								"fontWeight": "bolder",
								"fontFamily": "Open Sans",
								"fontSize": "16px"
							}}
							onClick={() => {
								history.replace(location.pathname);
							}}
							disabled={passwordUpdating}
						>
							HỦY
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</MuiThemeProvider>
	)
}

function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function UserAttributesToJSON(userAttributes = []) {
	let result = {};
	userAttributes.forEach(ele => {
		result[ele.Name] = ele.Value;
	})
	return result;
}

const getQueryParams = (url) => {
	let queryParams = {};
	//create an anchor tag to use the property called search
	let anchor = document.createElement('a');
	//assigning url to href of anchor tag
	anchor.href = url;
	//search property returns the query string of url
	let queryStrings = anchor.search.substring(1);
	let params = queryStrings.split('&');

	for (var i = 0; i < params.length; i++) {
		var pair = params[i].split('=');
		queryParams[pair[0]] = decodeURIComponent(pair[1]);
	}
	return queryParams;
};