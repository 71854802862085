/*global google*/
import './SlihomeMap.css';
import 'react-notifications-component/dist/theme.css'
import React from "react";
import ReactDOM from "react-dom"
import ReactDOMServer from "react-dom/server"
import Geocode from "react-geocode";
import { Loader } from "@googlemaps/js-api-loader";
import $ from "jquery";
import { store } from 'react-notifications-component';
import SlihomeCard from './SlihomeCard';
import SlihomeView from "./SlihomeView";
import { Grid, Button, GridList, GridListTile, Slider, Input, IconButton, Tooltip, CircularProgress, Select, MenuItem, FormControl, InputLabel, Checkbox, FormLabel, FormGroup, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, TextField, Slide } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { SlihomeAPIClient } from '../../Models/SlihomeAPI';
import { withRouter } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LS } from '../../Models/SlihomeLocalStorage';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const apiKey = process.env.REACT_APP_MAP_API_KEY;
const onlyOneInfoWindow = true;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
let map;
let location, history;
let mapPanning = false;
let nearByDataList = {};
let nearByDataMarkers = {};
let currentRoomDataWindow;
let generalTagsCount = 0;
let locationTagsCount = 0;
let districtFilter = false;
let queryFilter = {
  "country": "Việt Nam",
  "region": "Hà Nội",
  "district": "",
  "locality": "",
  "generalTag": "",
  "locationTag": "",
  "minPrice": 0,
  "maxPrice": 20000000,
  "minArea": 0,
  "maxArea": 100,
  "zoom": 15,
  "minLat": 20.0000001,
  "maxLat": 30.00000001,
  "minLong": 100.00000001,
  "maxLong": 111.000000001,
  "genderAllowance": "Male",
};

let sortBy = "priceAsc"; // ["priceDesc", "priceAsc", "areaDesc", areaAsc]

Geocode.setApiKey(apiKey);
Geocode.setLanguage("en");

class SlihomeMap extends React.Component {
  constructor(props) {
    super(props);
    this.currentMarkers = [];
    this.mapContainer = React.createRef();
    this.searchBox = React.createRef();
    this.currentLocationButton = React.createRef();
    this.applyFilterButtonRef = React.createRef();
    this.mapReady = false;
    this.queryParams = new URLSearchParams(this.props.location.search);
    location = this.props.location;
    history = this.props.history;
    this.state = {
      lat: parseFloat(this.queryParams.get("lat") || 21.028511),
      lng: parseFloat(this.queryParams.get("lng") || 105.804817),
      zoom: parseInt(this.queryParams.get("zoom") || 14),
      currentPosition: undefined,
      nearByData: [],
      filterError: null,
      queryAddress: "",
      showFilterPanel: true
    }
  }

  sortingCallback(a, b) {
    switch (sortBy) {
      case "priceAsc":
        return a.Information.Cost - b.Information.Cost;
      case "priceDesc":
        return b.Information.Cost - a.Information.Cost;
      case "areaAsc":
        return a.Information.Area - b.Information.Area;
      case "areaDesc":
        return b.Information.Area - a.Information.Area;
      default:
        return 0;
    }
  }

  findRoomWithRetry(){
    let retry = 0;
    this.findRoom(retry);
  }

  findRoom(retry = -1, options={}) {
    if(options.writeURI){
      this.writeMapURI();
      closeInfoWindow();
    }
    $('#loading').fadeIn();
    Geocode.fromLatLng(this.state.lat, this.state.lng).then(
      async response => {
        let locationContext = response.results[0];
        this.applyLocationFilter(locationContext);
        this.fetchNearByData(queryFilter).then(nearByData => {
          if (retry >= 0 && retry < 5 && Object.keys(nearByData).length <= 10 && map.getZoom() > 10) {
            google.maps.event.addListenerOnce(map, "zoom_changed", ()=>{
              this.findRoom(++retry, options);
            })
            map.setZoom(this.state.zoom - 1);
          }
          else {
            let renderList = Object.values(nearByData).sort(this.sortingCallback).map(ele => `${ele.Information.PK}@${ele.Information.SK}`)
            this.renderNearByData(renderList);
            $('#loading').fadeOut();
          }
        }).catch(err => {
          if (err) {
            console.error(err);
            switch (err.status) {
              case 502:
                store.addNotification({
                  title: "Lỗi tìm kiếm",
                  message:
                    `Hệ thống gặp lỗi vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                  type: "danger",
                  insert: "top",
                  container: "bottom-left",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 20000,
                    onScreen: true
                  }
                });
                break;
              default:
                store.addNotification({
                  title: "Lỗi tìm kiếm",
                  message:
                    `Lỗi không xác định vui lòng thử lại sau hoặc liên hệ quản trị viên`,
                  type: "danger",
                  insert: "top",
                  container: "bottom-left",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 20000,
                    onScreen: true
                  }
                });
                break;
            }
          }
          else {
            store.addNotification({
              title: "Lỗi tìm kiếm",
              message:
                `Lỗi không xác định vui lòng thử lại sau hoặc liên hệ quản trị viên`,
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 20000,
                onScreen: true
              }
            });
          }
          this.setState({
            filterError: {
              message: "Xảy ra lỗi trong quá trình tìm kiếm"
            }
          })
          $('#loading').fadeOut();
        })
      },
      error => {
        console.error(error);
        this.setState({
          filterError: {
            message: "Không lấy được thông tin khu vực cần tìm kiếm"
          }
        });
        $('#loading').fadeOut();
      }
    );
  }

  applyLocationFilter(locationContext = {}) {
    let context = locationContext.address_components;
    queryFilter.zoom = map.getZoom();
    let mapBounds = map.getBounds();
    queryFilter.minLat = mapBounds?.getSouthWest().lat();
    queryFilter.maxLat = mapBounds?.getNorthEast().lat();
    queryFilter.minLong = mapBounds?.getSouthWest().lng();
    queryFilter.maxLong = mapBounds?.getNorthEast().lng();
    queryFilter.country = "";
    queryFilter.region = "";
    queryFilter.district = "";
    queryFilter.locality = "";
    let addressContexts = [];
    context.forEach(ele => {
      if (ele.types.indexOf("country") > -1) {
        addressContexts[3] = ele.long_name;
        queryFilter.country = removeVietnameseTones(ele.long_name);
      }
      if (this.state.zoom >= 12 && ele.types.indexOf("administrative_area_level_1") > -1) {
        addressContexts[2] = ele.long_name;
        queryFilter.region = removeVietnameseTones(ele.long_name);
      }
      if (districtFilter && this.state.zoom >= 14 && ele.types.indexOf("administrative_area_level_2") > -1) {
        addressContexts[1] = ele.long_name;
        queryFilter.district = removeVietnameseTones(ele.long_name);
      }
      if (this.state.zoom >= 16 && ele.types.indexOf("sublocality_level_1") > -1) {
        addressContexts[0] = ele.long_name;
        queryFilter.locality = removeVietnameseTones(ele.long_name);
      }
    });
    this.setState({
      queryAddress: addressContexts.filter(Boolean).join(', ')
    })
  }

  renderNearByData(renderList) {
    Object.keys(nearByDataMarkers).filter(ele => {
      return renderList.indexOf(ele) < 0;
    }).forEach(ele => {
      nearByDataMarkers[ele].setMap(null);
    })
    renderList.forEach((key, index, arr) => {
      setTimeout(() => {
        if (nearByDataMarkers[key]) {
          nearByDataMarkers[key].setMap(map);
        }
      }, index * 100);
    })
    this.setState({
      filterError: null,
      nearByData: renderList
    })
  }

  async fetchNearByData(queryFilter) {
    return new Promise((resolve, reject) => {
      const show = (response) => {
        if (response.data.Success) {
          let nearByData = response.data.Result;

          nearByData.forEach((ele, index, arr) => {
            let info = ele.Information;
            let key = `${info.PK}@${info.SK}`;
            if (!nearByDataList[key]) {
              if (info.Longitude && info.Latitude) {
                // Store to cache
                nearByDataList[key] = info;

                let lat = info.Latitude
                let lng = info.Longitude

                let image = {
                  url: "/icon/Group 968@2x.png",
                  scaledSize: new google.maps.Size(30, 40),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(15, 40),
                }

                //Create new marker
                let tempMarker = new google.maps.Marker({
                  position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                  },
                  animation: google.maps.Animation.DROP,
                  title: info.RoomName,
                  icon: image
                });

                nearByDataMarkers[key] = tempMarker;

                tempMarker.addListener('click', ()=>{
                  if (!mapPanning) {
                    if (onlyOneInfoWindow) {
                      closeInfoWindow()
                    }
                    mapPanning = true;
                    let lat = parseFloat(nearByDataList[key].Latitude);
                    let lng = parseFloat(nearByDataList[key].Longitude);
                    smoothlyAnimatePanTo(map, { lat: lat, lng: lng }, () => {
                      if(window.innerWidth < 1920) {
                        this.setState({
                          showFilterPanel: false
                        })
                      }
                      map.setZoom(18);
                      mapPanning = false;
                      showInfoWindow(key)
                    });
                  }
                  else {
                    store.addNotification({
                      title: "Bấm chậm thôi",
                      message: "Tôi khuyên bạn nên bấm chậm thôi",
                      type: "info",
                      insert: "top",
                      container: "bottom-left",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true
                      }
                    });
                  }
                });
              }
              else {
                console.error(`Invalid data: ${key}`);
                delete nearByData[index];
              }
            }
          });
          resolve(nearByData);
        }
        else {
          reject(response);
        }
      }
      //fetch data
      SlihomeAPIClient.getRoomFilter(queryFilter)
        .then(show.bind(this))
        .catch(function (error) {
          if (error) {
            reject(error);
          }
          else {
            reject({ message: "Không xác định" });
          }
        });
    });
  }

  async showSpecificRoom (roomID) {
    closeInfoWindow()
    try {
      if(!(roomID || typeof roomID !== "string") || roomID.split('@').length<2) return;
      else{
        let SK = roomID.split('@')[roomID.split('@').length-1]
        let response = await SlihomeAPIClient.getOneRoom({
          roomID: SK
        });
        if(response.data.Success && response.data.Result.Count>0){
          let info = response.data.Result.Items[0];
          let key = `${info.PK}@${info.SK}`;
          nearByDataList[key] = info;
          if (info.Longitude && info.Latitude) {
            // Store to cache
            nearByDataList[key] = info;

            let lat = info.Latitude
            let lng = info.Longitude

            let image = {
              url: "/icon/Group 968@2x.png",
              scaledSize: new google.maps.Size(30, 40),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(15, 40),
            }

            //Create new marker
            let tempMarker = new google.maps.Marker({
              position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
              },
              animation: google.maps.Animation.DROP,
              title: info.RoomName,
              icon: image
            });

            tempMarker.addListener('click', ()=>{
              if (!mapPanning) {
                if (onlyOneInfoWindow) {
                  closeInfoWindow()
                }
                mapPanning = true;
                let lat = parseFloat(nearByDataList[key].Latitude);
                let lng = parseFloat(nearByDataList[key].Longitude);
                smoothlyAnimatePanTo(map, { lat: lat, lng: lng }, () => {
                  if(window.innerWidth < 1920) {
                    this.setState({
                      showFilterPanel: false
                    })
                  }
                  map.setZoom(18);
                  mapPanning = false;
                  showInfoWindow(key)
                  this.findRoom(-1, {
                    writeURI: false
                  });
                });
              }
              else {
                store.addNotification({
                  title: "Bấm chậm thôi",
                  message: "Tôi khuyên bạn nên bấm chậm thôi",
                  type: "info",
                  insert: "top",
                  container: "bottom-left",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                });
              }
            });

            nearByDataMarkers[key] = tempMarker;
            nearByDataMarkers[key].setMap(map);
            smoothlyAnimatePanTo(map, { lat: lat, lng: lng }, () => {
              if(window.innerWidth < 1920) {
                this.setState({
                  showFilterPanel: false
                })
              }
              map.setZoom(18);
              mapPanning = false;
              showInfoWindow(key);
              this.findRoom();
            });
          }
          else {
            throw Error(`Invalid room data:ID:${roomID}`)
          }
        }
        else {
          throw Error(`Room not found:ID:${roomID}`)
        }
      }
    } catch(err){
      console.error(err);
      store.addNotification({
        title: "Lỗi",
        message: "Không tìm thấy phòng",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      this.findRoomWithRetry();
    }
  }

  viewChangedHandler() {
    this.setState({
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
      zoom: map.getZoom(),
    })

    // Check if current location
    if (this.currentLocationButton.current) {
      if (this.state.currentPosition && (map.getCenter().lat() !== this.state.currentPosition.lat || map.getCenter().lng() !== this.state.currentPosition.lng)) {
        this.currentLocationButton.current.classList.remove('hidden');
      }
      else {
        this.currentLocationButton.current.classList.add('hidden');
      }
    }
  }

  writeMapURI() {
    try {
      let search = this.props.location.search.substr(-1)!=="&"?this.props.location.search.substring(1):this.props.location.search.substring(1, this.props.location.search.length-1);
      let queryParams = search.length===0?{}:JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      this.props.history.replace(JSONtoQuery({
        ...queryParams,
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
        zoom: map.getZoom(),
      }, true));
      location = this.props.location
      history = this.props.history
    } catch (err) {
      console.error(err)
    }
  }

  updateDimensions() {
    // closeInfoWindow()
  }

  finishInitMap() {
    // Loadmap done;
    // $('#loading').fadeOut();
    if(this.queryParams.get("roomID")){
      this.showSpecificRoom(decodeURIComponent(this.queryParams.get("roomID")));
    }
    else{
      this.findRoomWithRetry()
    }
  }

  componentDidMount() {
    // Bind action when click search button
    this.applyFilterButtonRef.current.addEventListener('click', this.findRoom.bind(this));

    // Handle window resizing
    window.addEventListener("resize", this.updateDimensions);
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"]
    });

    // Start load google map API
    loader.load().then(() => {
      // eslint-disable-next-line
      map = new google.maps.Map(this.mapContainer.current, {
        center: { lat: this.state.lat, lng: this.state.lng },
        zoom: this.state.zoom,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
        },
        streetViewControl: false,
      });

      // Search box
      const input = this.searchBox.current;
      input.classList.add("mapSearchBox");
      input.setAttribute("placeholder", "Tìm kiếm");
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(input);
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }
        else if (places.length === 1) {
          this.currentMarkers.forEach((marker) => {
            marker.setMap(null);
          });
          this.currentMarkers = [];
          if (!places[0].geometry) {
            return;
          }
          const icon = {
            url: places[0].icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };
          // Create a marker for each place.
          this.currentMarkers.push(
            new google.maps.Marker({
              map,
              icon,
              title: places[0].name,
              position: places[0].geometry.location,
            })
          );
          smoothlyAnimatePanTo(map, places[0].geometry.location, () => {
            google.maps.event.trigger(map, 'dragend');
          });
        }
        else {
          // Clear out the old markers.
          this.currentMarkers.forEach((marker) => {
            marker.setMap(null);
          });
          this.currentMarkers = [];
          // For each place, get the icon, name and location.
          const bounds = new google.maps.LatLngBounds();
          places.forEach((place) => {
            if (!place.geometry) {
              return;
            }
            const icon = {
              url: place.icon,
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25),
            };
            // Create a marker for each place.
            this.currentMarkers.push(
              new google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
              })
            );

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        }
      });

      // Trigger when map initialize finished
      google.maps.event.addListenerOnce(map, 'idle', this.finishInitMap.bind(this));

      // Close all info window when user start drag map
      google.maps.event.addListener(map, 'dragstart', () => {
        closeInfoWindow()
      });

      // Close all info window when zoom changed
      google.maps.event.addListener(map, 'zoom_changed', () => {
        this.viewChangedHandler()
      });

      // Watch view point
      map.addListener("dragend", () => {
        this.viewChangedHandler();
      });

      // Attemp to access current location
      if ("geolocation" in navigator) {
        // Browser support location
        const locationButton = this.currentLocationButton.current;
        map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(locationButton);
        $(locationButton).on("click", () => {
          if (this.state.currentPosition) {
            smoothlyAnimatePanTo(map, this.state.currentPosition, () => {
              if (map.getZoom() < 18) map.setZoom(18);
              google.maps.event.trigger(map, 'dragend');
            });
          }
          else {
            this.handleCurrentLocationDenied();
          }
        });

        // Checking permission or data
        navigator.geolocation.getCurrentPosition((position) => {
          // Access granted
          let currentPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.setState({
            currentPosition: currentPos
          });

          // Create marker for current location
          let currentLocationMarker = new google.maps.Marker({
            position: currentPos,
            map,
            title: "Bạn đang ở đây",
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 7,
              fillColor: "#4081EC",
              fillOpacity: 1,
              strokeColor: "#FFFFFF",
              strokeWeight: 3
            },
          });

          // Create info window for current location
          let currentLocationInfoWindow = new google.maps.InfoWindow({
            content: "Bạn đang ở đây"
          });

          // Watch for current location change
          navigator.geolocation.watchPosition((position) => {
            this.setState({
              currentPosition: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
            });
            currentLocationMarker.setPosition(this.state.currentPosition);
          });

          // Check expect view point in props
          if (this.props.location.search.length === 0) {
            function tempFunction() {
              let tempFunction = () => {
                if (map.getZoom() < 18) map.setZoom(18);
                google.maps.event.trigger(map, 'dragend');
                this.findRoomWithRetry()
                setTimeout(() => {
                  currentLocationInfoWindow.close();
                }, 5000)
              }
              currentLocationInfoWindow.open(map, currentLocationMarker);
              smoothlyAnimatePanTo(map, currentPos, tempFunction.bind(this));
            };
            google.maps.event.addListenerOnce(map, 'bounds_changed', tempFunction.bind(this));
          }
          else if(this.queryParams.get("roomID")){
            this.showSpecificRoom(decodeURIComponent(this.queryParams.get("roomID")));
          }
          else {
            google.maps.event.addListenerOnce(map, 'bounds_changed', this.findRoomWithRetry.bind(this));
          }

          // Click current location handler
          currentLocationMarker.addListener('click', (ev) => {
            smoothlyAnimatePanTo(map, this.state.currentPosition, () => {
              if (map.getZoom() < 18) map.setZoom(18);
              google.maps.event.trigger(map, 'dragend');
            });
          })
        }, (err) => {
          // Access denied
          this.handleCurrentLocationDenied(err);
        }, {
          timeout: 5000
        });
      } else {
        // Browser not support
        this.handleCurrentLocationDenied();
      }
    });
  }

  handleCurrentLocationDenied(err) {
    if (err) console.error(err);
    store.addNotification({
      title: "Lỗi định vị",
      message: "Không thể xác định vị trí hiện tại của bạn",
      type: "danger",
      insert: "top",
      container: "bottom-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  showDetailHandler(key) {
    if (!mapPanning) {
      // Show info window of selected room
      closeInfoWindow()
      mapPanning = true;
      let lat = parseFloat(nearByDataList[key].Latitude);
      let lng = parseFloat(nearByDataList[key].Longitude);
      smoothlyAnimatePanTo(map, { lat: lat, lng: lng }, () => {
        if(window.innerWidth < 1920) {
          this.setState({
            showFilterPanel: false
          })
        }
        showInfoWindow(key)
        map.setZoom(18);
        mapPanning = false;
      });
    }
    else {
      // Clicking too fast
      store.addNotification({
        title: "Bấm chậm thôi",
        message: "Tôi khuyên bạn nên bấm chậm thôi",
        type: "info",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  toggleFilterPanel = ()=>{
    this.setState({
      showFilterPanel: !this.state.showFilterPanel
    })
  }

  render() {
    return (
      <Grid container id="mainContainer" spacing={3}>
        <Grid container item lg={this.state.showFilterPanel ? 7 : 12} id="slihomeMap" style={{position: "absolute"}}>
          <div ref={this.mapContainer} id="mapContainer" />
          <input type="text" ref={this.searchBox} />
          <Tooltip title="Định vị" placement="top">
            <IconButton ref={this.currentLocationButton} className="currentLocationButton">
              <MyLocationIcon style={{ color: '#FFFFFF' }} />
            </IconButton>
          </Tooltip>
          <span id="toggleFilterPanelBtn" onClick={this.toggleFilterPanel}>
            {this.state.showFilterPanel ? <ArrowForwardIosIcon color="primary"/> : <ArrowBackIosIcon color="primary"/>}
          </span>
        </Grid>
        <Slide
          direction="left"
          timeout={500}
          in={this.state.showFilterPanel}
          style={{backgroundColor: "#fff"}}
        >
          <Grid container item lg={5} id="slihomeNearBy" justify="center" alignItems="flex-start">
            <div id="controlContainer">
              <Grid item xs={12}>
                <SlihomeFilterPanel id="filterContainer" applyFilterButtonRef={this.applyFilterButtonRef} />
              </Grid>
              <Grid item xs={12}>
                Kết quả tìm kiếm quanh: {this.state.queryAddress} ({this.state.nearByData.length} kết quả)
                </Grid>
            </div>
            <div id="resultContainer">
              <Grid container id="loading" justify="center" alignItems="center">
                <Grid item>
                  <CircularProgress color="secondary" />
                </Grid>
              </Grid>
              {
                this.state.filterError
                  ?
                  <Grid container justify="center" alignItems="center" spacing={2} direction="column" style={{ width: "100%", height: "100%" }}>
                    <Grid item>
                      <img src="/icon/error.svg" alt="Xảy ra lỗi"></img>
                    </Grid>
                    <Grid item>
                      {this.state.filterError.message}
                    </Grid>
                  </Grid>
                  :
                  <SlihomeCardList data={this.state.nearByData} col={this.state.nearByDataCol} onCardClick={this.showDetailHandler.bind(this)} id="viewList" />
              }

            </div>
          </Grid>
        </Slide>
      </Grid>
    );
  }
}

class SlihomeFilterPanel extends React.Component {
  constructor(props) {
    super(props);
    this.reactTags = React.createRef()
    this.state = {
      hidden: props.hidden || false,
      tags: [],
      suggestions: [],
      priceRange: [500000, 2000000],
      areaRange: [10, 30],
      genderAllowance: "Both",
      districtFilter: districtFilter,
      sortBy: sortBy
    };
  }

  getTagList = async function(){
    // Get general tag
    let generalTags = await LS.getGeneralTags()
    generalTagsCount = Object.keys(generalTags).length;
    let suggestions = [];
    Object.keys(generalTags).forEach(ele => {
      suggestions.push({
        id: `general@${ele}`,
        name: generalTags[ele]
      });
    });

    // Get location tag
    let locationTags = await LS.getLocationTags()
    locationTagsCount = Object.keys(locationTags).length;
    Object.keys(locationTags).forEach(ele => {
      suggestions.push({
        id: `location@${ele}`,
        name: `Gần ${locationTags[ele]}`
      });
    });
    this.setState({
      suggestions: this.state.suggestions.concat(suggestions)
    })
  }

  componentDidMount() {
    this.getTagList()
  }

  tagOnChange(event, value) {
    this.setState({tags: value})
  }

  priceSliderOnChange(event, newVal) {
    this.setState({
      priceRange: newVal
    })
  }

  priceMinOnChange(event) {
    let newVal = event.target.value === '' ? 0 : removeCommas(event.target.value)
    if (newVal > this.state.priceRange[1]) {
      this.setState({
        priceRange: [this.state.priceRange[1], newVal]
      })
    }
    else {
      this.setState({
        priceRange: [newVal, this.state.priceRange[1]]
      })
    }
  }

  priceMaxOnChange(event) {
    let newVal = event.target.value === '' ? 2000000 : removeCommas(event.target.value)
    if (newVal < this.state.priceRange[0]) {
      this.setState({
        priceRange: [newVal, this.state.priceRange[0]]
      })
    }
    else {
      this.setState({
        priceRange: [this.state.priceRange[0], newVal]
      })
    }
  }

  areaSliderOnChange(event, newVal) {
    this.setState({
      areaRange: newVal
    })
  }

  areaMinOnChange(event) {
    let newVal = event.target.value === '' ? 0 : event.target.value
    if (newVal > this.state.areaRange[1]) {
      this.setState({
        areaRange: [this.state.areaRange[1], newVal]
      })
    }
    else {
      this.setState({
        areaRange: [newVal, this.state.areaRange[1]]
      })
    }
  }

  areaMaxOnChange(event) {
    let newVal = event.target.value === '' ? 100 : event.target.value
    if (newVal < this.state.areaRange[0]) {
      this.setState({
        areaRange: [newVal, this.state.areaRange[0]]
      })
    }
    else {
      this.setState({
        areaRange: [this.state.areaRange[0], newVal]
      })
    }
  }

  genderAllowanceChange(event) {
    this.setState({
      genderAllowance: event.target.value
    })
  }

  districtFilterChange(event, value) {
    this.setState({
      districtFilter: value
    })
  }

  generateTagFilter(tagList = []) {
    let general = [];
    let location = [];
    tagList.forEach(ele => {
      let tagType = ele.id.split("@")[0];
      let tagIndex = parseInt(ele.id.split("@")[1]);
      switch (tagType) {
        case "general":
          general.push(tagIndex);
          break;
        case "location":
          location.push(tagIndex);
          break;
        default:
      }
    })
    let generalTag = Array.apply(null, Array(generalTagsCount)).map((ele, index) => general.indexOf(index) < 0 ? 0 : 1).join(',');
    let locationTag = Array.apply(null, Array(locationTagsCount)).map((ele, index) => location.indexOf(index) < 0 ? 0 : 1).join(',');
    return {
      generalTag: generalTag,
      locationTag: locationTag
    }
  }

  sortByChange(event) {
    this.setState({
      sortBy: event.target.value
    });
    this.props.applyFilterButtonRef.current.click();
  }

  render() {
    let tags = this.generateTagFilter(this.state.tags);
    sortBy = this.state.sortBy;
    districtFilter = this.state.districtFilter;
    queryFilter.generalTag = tags.generalTag;
    queryFilter.locationTag = tags.locationTag;
    queryFilter.minPrice = this.state.priceRange[0];
    queryFilter.maxPrice = this.state.priceRange[1];
    queryFilter.minArea = this.state.areaRange[0];
    queryFilter.maxArea = this.state.areaRange[1];
    queryFilter.genderAllowance = this.state.genderAllowance === "Both" ? undefined : this.state.genderAllowance;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<Tooltip title="Bộ lọc mở rộng"><ExpandMoreIcon style={{marginTop: -10}} /></Tooltip>}
          aria-controls="search-filter-content"
          id="search-filter-header"
        >
          <Grid container spacing={2} id={this.props.id} justify="center" 
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}>
            <Grid item xs={10} lg={10}>
              <Autocomplete
                size="small"
                multiple
                limitTags={2}
                id="filter-tags"
                options={this.state.suggestions}
                onChange={this.tagOnChange.bind(this)}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tìm theo tag" placeholder="Thêm tag" />
                )}
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <Button 
                variant="contained" 
                id="applyFilter" 
                ref={this.props.applyFilterButtonRef}
              >
                <SearchIcon style={{ color: "#FFFFFF" }} />
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justify="center">
            <Grid container item lg={12} spacing={2} justify="center">
              <Grid item xs={3} sm={2} md={2} lg={3} xl={2}>
                <Input
                  value={numberWithCommas(this.state.priceRange[0])}
                  margin="dense"
                  onChange={this.priceMinOnChange.bind(this)}
                  inputProps={{
                    'aria-labelledby': 'input-slider',
                  }}
                  endAdornment={'₫'}
                />
              </Grid>
              <Grid item xs={6} sm={8} md={8} lg={6} xl={8}>
                <Slider
                  value={this.state.priceRange}
                  onChange={this.priceSliderOnChange.bind(this)}
                  aria-labelledby="range-slider"
                  step={500000}
                  min={500000}
                  max={20000000}
                />
              </Grid>
              <Grid item xs={3} sm={2} md={2} lg={3} xl={2}>
                <Input
                  value={numberWithCommas(this.state.priceRange[1])}
                  margin="dense"
                  onChange={this.priceMaxOnChange.bind(this)}
                  inputProps={{
                    'aria-labelledby': 'input-slider'
                  }}
                  endAdornment={'₫'}
                />
              </Grid>
            </Grid>
            <Grid container item lg={12} spacing={2} justify="center">
              <Grid item xs={3} sm={2} md={2} lg={3} xl={2}>
                <Input
                  value={this.state.areaRange[0]}
                  margin="dense"
                  onChange={this.areaMinOnChange.bind(this)}
                  inputProps={{
                    'aria-labelledby': 'input-slider',
                  }}
                  endAdornment={'m²'}
                />
              </Grid>
              <Grid item xs={6} sm={8} md={8} lg={6} xl={8}>
                <Slider
                  value={this.state.areaRange}
                  onChange={this.areaSliderOnChange.bind(this)}
                  aria-labelledby="range-slider"
                  step={1}
                  min={0}
                  max={100}
                />
              </Grid>
              <Grid item xs={3} sm={2} md={2} lg={3} xl={2}>
                <Input
                  value={this.state.areaRange[1]}
                  margin="dense"
                  onChange={this.areaMaxOnChange.bind(this)}
                  inputProps={{
                    'aria-labelledby': 'input-slider'
                  }}
                  endAdornment={'m²'}
                />
              </Grid>
            </Grid>
            <Grid container item lg={12} spacing={2} justify="center">
              <Grid item lg={4}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="gender-allowance-select">Giới tính</InputLabel>
                  <Select
                    labelId="gender-allowance-select"
                    value={this.state.genderAllowance}
                    onChange={this.genderAllowanceChange.bind(this)}
                  >
                    <MenuItem value={"Male"}>Nam</MenuItem>
                    <MenuItem value={"Female"}>Nữ</MenuItem>
                    <MenuItem value={"Both"}>Cả hai</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="sort-by">Sắp xếp</InputLabel>
                  <Select
                    labelId="sort-by"
                    value={this.state.sortBy}
                    onChange={this.sortByChange.bind(this)}
                  >
                    <MenuItem value={"priceAsc"}>Giá tăng dần</MenuItem>
                    <MenuItem value={"priceDesc"}>Giá giảm dần</MenuItem>
                    <MenuItem value={"areaAsc"}>Kích thước tăng dần</MenuItem>
                    <MenuItem value={"areaDesc"}>Kích thước giảm dần</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel component="legend" style={{fontSize: 12}}>Tùy chọn</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={this.state.districtFilter} onChange={this.districtFilterChange.bind(this)} name="district" />}
                      label="Tìm theo quận"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
}

function writeRoomURI(key = null) {
  try {
    let search = location.search.substr(-1)!=="&"?location.search.substring(1):location.search.substring(1, location.search.length-1);
    let queryParams = search.length===0?{}:JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    let newQueryParams = {
      ...queryParams,
      roomID: key
    };
    if(!key) delete newQueryParams.roomID
    history.replace(JSONtoQuery(newQueryParams), true);
  } catch (err){
    console.error(err);
  }
}

function showInfoWindow(key) {
  writeRoomURI(key)
  //Create new InfoWindow
  let tempInfoWindowId = "info-window-content-" + key;
  currentRoomDataWindow = new google.maps.InfoWindow({
    content: ReactDOMServer.renderToString(<div id={tempInfoWindowId}></div>),
    minWidth: $('#mapContainer').width() * 0.8,
    maxWidth: $('#mapContainer').width() * 0.9,
  });
  currentRoomDataWindow.open(map, nearByDataMarkers[key])
  google.maps.event.addListenerOnce(currentRoomDataWindow, 'domready', () => {
    ReactDOM.render(
      <SlihomeView data={nearByDataList[key]}></SlihomeView>,
      document.getElementById(tempInfoWindowId)
    );
  });
}

function closeInfoWindow() {
  if(currentRoomDataWindow){
    writeRoomURI()
    currentRoomDataWindow.close()
    currentRoomDataWindow=null
  }
}

class SlihomeCardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataList: props.data,
      cardHeight: 'fit-content',
      cardWidth: 275
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      dataList: nextProps.data,
    };
  }

  componentDidMount() {
    this.setState({
      col: Math.floor($('#viewList').width() / this.state.cardWidth)
    })
  }

  render() {
    if (this.state.dataList.length) {
      return (
        <GridList id={this.props.id} cols={this.state.col} spacing={1}>
          {this.state.dataList.map(ele =>
            <GridListTile key={`GridTile@${ele}}`}>
              <SlihomeCard
                key={`Card@${ele}`}
                data={nearByDataList[ele]}
                cardHeight={this.state.cardHeight}
                cardWidth={this.state.cardWidth}
                onClick={() => {
                  this.props.onCardClick(ele)
                }}>
              </SlihomeCard>
            </GridListTile>
          )}
        </GridList>
      )
    }
    else {
      return (
        <Grid container id={this.props.id} justify="center" alignItems="center" spacing={2} direction="column" style={{ height: "100%" }}>
          <Grid item>
            <img src="/icon/search-idle.svg" alt="Không có kết quả"></img>
          </Grid>
          <Grid item>
            Không có kết quả
        </Grid>
        </Grid>)
    }
  }
}

export default withRouter(SlihomeMap);

/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function project(latLng) {
  var TILE_SIZE = 256

  var siny = Math.sin((latLng.lat instanceof Function ? latLng.lat() : latLng.lat) * Math.PI / 180)

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999)

  return new google.maps.Point(
    TILE_SIZE * (0.5 + (latLng.lng instanceof Function ? latLng.lng() : latLng.lng) / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)))
}

/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function getPixel(latLng, zoom) {
  var scale = 1 << zoom
  var worldCoordinate = project(latLng)

  return new google.maps.Point(
    Math.floor(worldCoordinate.x * scale),
    Math.floor(worldCoordinate.y * scale))
}

/**
* Given a map, return the map dimension (width and height)
* in pixels.
**/
function getMapDimenInPixels(map) {
  var zoom = map.getZoom()
  var bounds = map.getBounds()
  var southWestPixel = getPixel(bounds.getSouthWest(), zoom)
  var northEastPixel = getPixel(bounds.getNorthEast(), zoom)

  return {
    width: Math.abs(southWestPixel.x - northEastPixel.x),
    height: Math.abs(southWestPixel.y - northEastPixel.y)
  }
}

/**
* Given a map and a destLatLng returns true if calling
* map.panTo(destLatLng) will be smoothly animated or false
* otherwise.
*
* optionalZoomLevel can be optionally be provided and if so
* returns true if map.panTo(destLatLng) would be smoothly animated
* at optionalZoomLevel.
**/
function willAnimatePanTo(map, destLatLng, optionalZoomLevel) {
  var dimen = getMapDimenInPixels(map)

  var mapCenter = map.getCenter()
  optionalZoomLevel = !!optionalZoomLevel ? optionalZoomLevel : map.getZoom()

  var destPixel = getPixel(destLatLng, optionalZoomLevel)
  var mapPixel = getPixel(mapCenter, optionalZoomLevel)
  var diffX = Math.abs(destPixel.x - mapPixel.x)
  var diffY = Math.abs(destPixel.y - mapPixel.y)

  return (diffX < dimen.width && diffY < dimen.height)
}

/**
* Returns the optimal zoom value when animating 
* the zoom out.
*
* The maximum change will be currentZoom - 3.
* Changing the zoom with a difference greater than 
* 3 levels will cause the map to "jump" and not
* smoothly animate.
*
* Unfortunately the magical number "3" was empirically
* determined as we could not find any official docs
* about it.
**/
function getOptimalZoomOut(map, latLng, currentZoom) {
  if (willAnimatePanTo(map, latLng, currentZoom - 1)) {
    return currentZoom - 1
  } else if (willAnimatePanTo(map, latLng, currentZoom - 2)) {
    return currentZoom - 2
  } else {
    return currentZoom - 3
  }
}

/**
* Given a map and a destLatLng, smoothly animates the map center to
* destLatLng by zooming out until distance (in pixels) between map center
* and destLatLng are less than map width and height, then panTo to destLatLng
* and finally animate to restore the initial zoom.
*
* optionalAnimationEndCallback can be optionally be provided and if so
* it will be called when the animation ends
**/
function smoothlyAnimatePanToWorkarround(map, destLatLng, optionalAnimationEndCallback) {
  var initialZoom = map.getZoom(), listener

  function zoomIn() {
    if (map.getZoom() < initialZoom) {
      map.setZoom(Math.min(map.getZoom() + 3, initialZoom))
    } else {
      google.maps.event.removeListener(listener)
      if (!!optionalAnimationEndCallback) {
        optionalAnimationEndCallback();
      }

      //here you should (re?)enable only the ui controls that make sense to your app 
      map.setOptions({ draggable: true, zoomControl: true, scrollwheel: true, disableDoubleClickZoom: false })
    }
  }

  function zoomOut() {
    if (willAnimatePanTo(map, destLatLng)) {
      google.maps.event.removeListener(listener)
      listener = google.maps.event.addListener(map, 'idle', zoomIn)
      map.panTo(destLatLng)
    } else {
      map.setZoom(getOptimalZoomOut(map, destLatLng, map.getZoom()))
    }
  }
  //here you should disable all the ui controls that your app uses
  map.setOptions({ draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true })
  map.setZoom(getOptimalZoomOut(map, destLatLng, initialZoom))
  listener = google.maps.event.addListener(map, 'idle', zoomOut)
}

function smoothlyAnimatePanTo(map, destLatLng, callback) {
  if (willAnimatePanTo(map, destLatLng)) {
    google.maps.event.addListenerOnce(map, "idle", () => {
      if (!!callback) {
        callback();
      }
    })
    map.panTo(destLatLng)
  } else {
    smoothlyAnimatePanToWorkarround(map, destLatLng, callback);
  }
}

// eslint-disable-next-line
function JSONtoQuery(params, question = true) {
  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }
  let cleanParams = clean(params);
  return question ? `?${Object.keys(cleanParams).map(key => cleanParams[key] ? encodeURIComponent(key) + '=' + encodeURIComponent(cleanParams[key]) : "").join('&')}` : Object.keys(cleanParams).map(key => cleanParams[key] ? encodeURIComponent(key) + '=' + encodeURIComponent(cleanParams[key]) : "").join('&');
}

function removeCommas(val) {
  return parseInt(val.replace(/,/g, ''));
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeVietnameseTones(str, needPatch = true) {
  if (str === "Vietnam") return "Việt Nam"
  return str;
  // str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  // str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  // str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  // str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  // str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  // str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  // str = str.replace(/đ/g, "d");
  // str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  // str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  // str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  // str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  // str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  // str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  // str = str.replace(/Đ/g, "D");
  // // Some system encode vietnamese combining accent as individual utf-8 characters
  // // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  // str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  // str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // // Remove spaces
  // // Bỏ các khoảng trắng
  // str = str.replace(/ /g, "");
  // str = str.trim();
  // // Remove punctuations
  // // Bỏ dấu câu, kí tự đặc biệt
  // // eslint-disable-next-line
  // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");

  // // Patch for HaNoi => Hanoi
  // if(str === "HaNoi") return "Hanoi"
  // return str;
}