import React, { useCallback, useEffect } from 'react';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Tabs, Tab, CircularProgress } from '@material-ui/core';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import "../SHSignIn/SlihomeAuth.css";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SlihomeAPIClient } from '../Models/SlihomeAPI';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    fontFamily: "Open Sans",
  },
  typography:{
    fontWeight: "800",
  },
  typographyHeader:{
    color: '#484848',
    fontWeight: "bold",
    fontSize: "30px",
    fontFamily: "Open Sans",
  },
  typographyLink: {
    color: '#888888',
    fontWeight: "600",
  },
  typographyHint: {
    color: '#CECECE',
    fontWeight: "600",
    fontSize: "14px",
  },
  image: {
    backgroundImage: 'url(images/xAsset-1-1536x995.png.pagespeed.ic.U8-BcIT-1J.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  notchedOutline: {
    borderRadius: "0",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderColor: "#f02d4e !important"
  },
  tab: {
    color: "#f02d4e",
    borderColor: "#f02d4e",
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://slihome.com/">
        SLI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const getQueryParams = (url) => {
  let queryParams = {};
//create an anchor tag to use the property called search
  let anchor = document.createElement('a');
//assigning url to href of anchor tag
  anchor.href = url;
//search property returns the query string of url
  let queryStrings = anchor.search.substring(1);
  let params = queryStrings.split('&');

  for (var i = 0; i < params.length; i++) {
      var pair = params[i].split('=');
      queryParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  return queryParams;
};

export default function ForgotPasswordSide() {
  const classes = useStyles();
  let location = useLocation();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  let params = getQueryParams(location.search);

  const submitHandler = useCallback(()=>{
    switch(value){
      case 0:
        let email = $('#email').val();
        if(!email){
          notifyInvalidField([{
            type:"email",
            checkResult: "Vui lòng nhập địa chỉ email"
          }])
        }
        else if(validateEmail(email)){
          setLoading(true);
          SlihomeAPIClient.forgotPassword({
            username: email
          }).then(response=>{
            if(response.data.statusCode===200){
              setValue(1);
              $('#email').val(email);
              $('#phase2Notification').html(`Một mã xác thực đã được gửi  gửi đến hòm thư của bạn (${email})`)
            }
            else {
              if(response.data.body.code==="LimitExceededException"){
                notifyInvalidField([{
                  type: "email",
                  checkResult: "Email này đã vượt quá số lần yêu cầu, xin vui lòng thử lại sau"
                }])
              }
              else{
                notifyInvalidField([{
                  type: "email",
                  checkResult: "Lỗi không xác định. Liên hệ quản trị viên để được hỗ trợ"
                }])
              }
            }
          }).catch(err=>{
            console.error(err.response);
            notifyInvalidField([{
              type: "email",
              checkResult: "Lỗi không xác định. Liên hệ quản trị viên để được hỗ trợ"
            }])
          }).finally(()=>{
            setLoading(false);
          })
        }
        else {
          notifyInvalidField([{
            type:"email",
            checkResult: "Sai định dạng địa chỉ email"
          }])
        }
        break;
      case 1:
        let code = $('#code').val();
        let password = $('#password').val();
        let confirmPassword = $('#confirmPassword').val();
        let checkResult = checkPhase2Context(code, password, confirmPassword);
        if(checkResult.length===0){
          setLoading(true);
          SlihomeAPIClient.confirmForgotPassword({
            username: $('#email').val(),
            newPassword: password,
            confirmationCode: code,
          }).then(response=>{
            if(response.data.statusCode===200){
              setValue(2);
            }
            else if(response.data.body.code==="CodeMismatchException"){
              notifyInvalidField([{
                type: "code",
                checkResult: "Mã xác thực không đúng"
              }])
            }
            else if(response.data.body.code==="InvalidPasswordException"){
              notifyInvalidField([{
                type: "password",
                checkResult: "Mật khẩu cần có cả chữ cái, số và dài hơn 8 ký tự"
              }])
            }
            else {
              $('#submitButton').html("Xảy ra lỗi");
              console.error(response)
            }
          }).catch(err=>{
            console.error(err.response);
            $('#submitButton').html("Xảy ra lỗi");
          }).finally(()=>{
            setLoading(false);
          })
        } 
        else{
          notifyInvalidField(checkResult);
        }
        break;
      default:
    }
  }, [value]);

  useEffect(()=>{
    if(params.email){
      $('#email').val(params.email);
    }
    $('#email').on("keydown", function(event) {
      if (event.key === 13) {
        event.preventDefault();
        submitHandler();
      }
    });
  }, [params.email, submitHandler])

  function notifyInvalidField(checkResult=[]) {
    checkResult.forEach(ele=>{
      switch(ele.type){
        case "code":
          $('#codeResultText').html(ele.checkResult);
          $('#codeResultHolder').fadeIn().removeClass("transparent");
          break;
        case "email":
          $('#emailResultText').html(ele.checkResult);
          $('#emailResultHolder').fadeIn().removeClass("transparent");
          break;
        case "password":
          $('#passwordResultText').html(ele.checkResult);
          $('#passwordResultHolder').fadeIn().removeClass("transparent");
          break;
        case "confirmPassword":
          $('#confirmPasswordResultText').html(ele.checkResult);
          $('#confirmPasswordResultHolder').fadeIn().removeClass("transparent");
          break;
        default:
      }
    })
  }

  function checkPhase2Context(code, password, confirmPassword) {
    let checkResult = []
    if(!code) {
      checkResult.push({
        type: "code",
        checkResult: "Vui lòng nhập mã xác thực"
      })
    }
    if(!password) {
      checkResult.push({
        type: "password",
        checkResult: "Vui lòng nhập mật khẩu"
      })
    }
    else if(password.length < 8) {
      checkResult.push({
        type: "password",
        checkResult: "Mật khẩu chưa đủ dài (8 ký tự)"
      })
    }
    if(!confirmPassword) {
      checkResult.push({
        type: "confirmPassword",
        checkResult: "Vui lòng nhập lại mật khẩu"
      })
    }
    if(password&&confirmPassword&&password!==confirmPassword){
      checkResult.push({
        type: "confirmPassword",
        checkResult: "Mật khẩu không khớp"
      })
    }
    return checkResult;
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function clearResult() {
    $('.resultHolder').addClass('transparent');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container component="main" className={classes.root} id="main">
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <a href="/">
            <img src="/images/cropped-imgpsh_fullsize_anim-192x192.png" alt="banner"/>
          </a>
          <Typography component="h1" className={classes.typographyHeader}>
            Quên mật khẩu
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
          >
            <Tab label="Nhập email" />
            <Tab label="Xác thực email" disabled/>
            <Tab label="Xác nhận" disabled/>
          </Tabs>
          <TabPanel value={value} index={0} style={{width: "100%"}}>
            <form className={classes.form} noValidate>
              <TextField
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                disabled={loading}
                autoFocus
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <div id="emailResultHolder" className="resultHolder transparent">
                    <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                    <span id="emailResultText"></span>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="center">
                <Grid item xs={10}>
                  <Button
                    type="button"
                    id="submitButton"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="signin-btn"
                    style={{
                      "borderRadius": "10rem",
                      "height": "3rem",
                      "fontWeight": "bolder",
                      "fontFamily": "Open Sans",
                      "fontSize": "16px"
                    }}
                    onClick={submitHandler}
                    disabled={loading}
                  >
                    {loading?<CircularProgress style={{color: "#FFFFFF"}}/>:"XÁC NHẬN"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
          <TabPanel value={value} index={1} style={{width: "100%"}}>
            <form className={classes.form} noValidate>
              <Typography id="phase2Notification">
                Xác thực email
              </Typography>
              <TextField 
                  onChange={clearResult}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="email"
                  label="Email"
                  type="text"
                  id="email"
                  autoComplete="username"
                  disabled
                  hidden
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              <TextField 
                  onChange={clearResult}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="code"
                  label="Mã xác thực"
                  type="code"
                  id="code"
                  disabled={loading}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <Grid container spacing={2} justify="space-between">
                  <Grid item id="codeResultHolder" className="resultHolder transparent" style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                    <span id="codeResultText">bắt buộc</span>
                  </Grid>
                </Grid>
              <TextField 
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="new-password"
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="passwordResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="passwordResultText">bắt buộc</span>
                </Grid>
              </Grid>
              <TextField 
                onChange={clearResult}
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Nhập lại mật khẩu"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                disabled={loading}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item id="confirmPasswordResultHolder" className="resultHolder transparent" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <img src="icon/Component-90–4.svg" alt="x-icon" className="inlineIcon"/>
                  <span id="confirmPasswordResultText">bắt buộc</span>
                </Grid>
              </Grid> 
              <Grid container spacing={2} justify="center">
                <Grid item xs={10}>
                  <Button
                    type="button"
                    id="submitButton"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="signin-btn"
                    style={{
                      "borderRadius": "10rem",
                      "height": "3rem",
                      "fontWeight": "bolder",
                      "fontFamily": "Open Sans",
                      "fontSize": "16px"
                    }}
                    onClick={submitHandler}
                    disabled={loading}
                  >
                    {loading?<CircularProgress style={{color: "#FFFFFF"}}/>:"XÁC NHẬN"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
          <TabPanel value={value} index={2} style={{width: "100%"}}>
            <Grid container justify="center">
              <ThumbUpAltRoundedIcon color="secondary" style={{fontSize:"3rem", marginTop:"1rem"}} />
            </Grid>
            <Typography component="h4" variant="h4" style={{textAlign: "center"}}>
              Đổi mật khẩu thành công
            </Typography>
          </TabPanel>
          <Grid container spacing={2} justify="center" id="backToSignIn">
            <Grid item>
              <Link href="/dang-nhap" className="gradient-text">
                <b> &#8678; </b> Quay lại trang đăng nhập
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}