/* eslint-disable import/no-anonymous-default-export */
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default (props) => {
    return (
        <Grid container justify="center" alignItems="center" style={{paddingTop: "2rem"}}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cẩm nang khi sử dụng dịch vụ Slihome</title>
                <meta name="description" content="Hướng dẫn và một số mẹo trong quá trình sử dụng dịch vụ tìm trọ Slihome" />
            </Helmet>
            <Grid item>
                <img src="/icon/construction.svg" style={{width: "10rem", margin: "auto"}} alt="construction icon"/>
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    Trang {props.pageName || "này"} đang trong quá trình bảo trì hoặc nâng cấp
                </Typography>
                <br/>
                <Typography variant="h5">
                Nhấn vào <Link to="/" style={{color: "#ea285e"}}><u>đây</u></Link> để quay lại trang chủ
                </Typography>
            </Grid>
        </Grid>
    )
}