import React from 'react';
import './SHFooter.css';
import { NavLink } from "react-router-dom";

const SHFooter = () => {
    return (
        <footer className="page-footer font-small footer">
                <div className="content-footer container">
                    <div className="top-footer d-flex justify-content-between">
                        <img className="logo" src="images/Artboard – 89.svg" alt=""/>
                        <div className="d-flex mt-1 list-link">
                            <NavLink to="/">
                                <h6 variant="h6" className={`link-footer`}>
                                Giới thiệu
                                </h6>
                            </NavLink>
                            <NavLink to="/app">
                                <h6 variant="h6" className={`link-footer`}>
                                Tìm nhà
                                </h6>
                            </NavLink>
                        
                            <NavLink to="/term-of-service">
                                <h6 variant="h6" className={`link-footer`}>
                                Điều khoản
                                </h6>
                            </NavLink>
                            <NavLink to="/private-policy">
                                <h6 variant="h6" className={`link-footer`}>
                                Chính sách bảo mật
                                </h6>
                            </NavLink>
                            <a href="https://host.slihome.com">
                                <h6 variant="h6"  className={`link-footer`}>
                                Dành cho chủ trọ
                                </h6>
                            </a>
                        </div> 
                        <div>
                            <p className="follow-text">Theo dõi chúng tôi</p>
                            <a href="https://www.facebook.com/slitizen/" alt="https://www.facebook.com/slitizen/">
                                <img src="/images/facebook.svg" className="icon-facebook" alt="facebook fan page"/>
                            </a>
                        </div> 
                    </div>
                </div>
                <div className="copyRight">© 2020 Copyright: slihome.com</div>
            </footer>
    )
}

export default SHFooter;