import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactNotification from 'react-notifications-component'
import { PATHS, ROUTES } from './Const/index';
import WrapRouterComponent from './SHComponents/WrapRouterComponent';
import { MuiThemeProvider } from '@material-ui/core';
import SlihomeTheme from './Models/SlihomeMuiTheme';

export default function SlihomeRouter(props) {
	// eslint-disable-next-line
	const [render, reRender] = useState(true);

	useEffect(()=>{
		function handleInvalidAuthInfo(){
			reRender(r=>!r);
		}
		window.addEventListener("invalidAuthInfo", handleInvalidAuthInfo)
		return ()=>{
			window.removeEventListener("invalidAuthInfo", handleInvalidAuthInfo);
		}
	}, []);

	return (
		<MuiThemeProvider theme={SlihomeTheme}>
			<Router>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Nền Tảng Tìm Trọ Thông Minh Và Ưu Việt Hàng Đầu</title>
					<meta name="description" content="Nền tảng giúp các bạn tìm cho mình 1 ngôi nhà trọ phù hợp và ưng ý nhất
						Giao diện trực quan, thông tin chi tiết và xác thực đảm bảo thông tin nhà trọ được hiển thị chính xác tới người thuê"/>
				</Helmet>
				<ReactNotification />
				<Switch>
					<Route exact path={PATHS.ROOT}>
						<Redirect to={PATHS.HOME_PAGE}/>
					</Route>
					{ROUTES.map(routeProps => <WrapRouterComponent {...routeProps} key={routeProps.path} />)}
					<Route path={PATHS.ALL}>
						<Redirect to={PATHS.NOT_FOUND}/>
					</Route>
				</Switch>
			</Router>
		</MuiThemeProvider>
	)
}