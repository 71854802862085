import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const APIEndpoint = process.env.REACT_APP_API_ENDPOINT
const cognitoEndPoint = process.env.REACT_APP_COGNITO_ENDPOINT
const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID
const cognitoClientSecret = process.env.REACT_APP_COGNITO_CLIENT_SECRET

const isReauthSuccess = async (failedRequest)=>{
    return new Promise((resolve, reject)=>{
        try{
            let api = axios.create({
                baseURL: APIEndpoint
            })
            let authInfo = JSON.parse(localStorage.getItem('authInfo'));
            let username = authInfo.Username;
            api.post("/user/reauth", {
                refreshToken: authInfo.RefreshToken,
                username: authInfo.Username,
            }).then(res=>{
                if(res.status === 200){
                    let newAuthInfo = res.data.AuthenticationResult;
                    newAuthInfo['Username'] = username;
                    newAuthInfo['RefreshToken'] = authInfo.RefreshToken;
                    localStorage.setItem('authInfo', JSON.stringify(newAuthInfo));
                    failedRequest.response.config.headers['Authorization'] = newAuthInfo.IdToken;
                    window.dispatchEvent(new Event('userInfoChanged'));
                    console.log("Reauth Success");
                    resolve();
                }
                else {
                    console.log("Reauth failed: code " + res.status);
                    localStorage.removeItem('authInfo');
                    window.dispatchEvent(new Event("invalidAuthInfo"));
                    reject();
                }
            }).catch(error=>{
                console.error("Reauth failed: " + error.response.data.message);
                localStorage.removeItem('authInfo');
                window.dispatchEvent(new Event("invalidAuthInfo"));
                reject();
            })
        }
        catch(err){
            console.error("Reauth failed: " + err);
            localStorage.removeItem('authInfo');
            window.dispatchEvent(new Event("invalidAuthInfo"));
            reject();
        }
    })
}

let APIClient = axios.create({
    baseURL: APIEndpoint,
    timeout: 30000,
});

class SlihomeAPI {
    constructor(){
        this.APIClient = axios.create({
            baseURL: APIEndpoint,
            timeout: 30000,
        });
        createAuthRefreshInterceptor(this.APIClient, isReauthSuccess, {
            pauseInstanceWhileRefreshing: true
        });
    };

    signIn = function (data={
        username:"",
        password:""
    }, config) {
        return this.APIClient.post('/user/lessee-login', data, config);
    }

    signUp = function (data={
        username: "",
        password: "",
        userAttributes: {
          email: "",
          name: ""
        }
    }, config) {
        return this.APIClient.post("/user/lessee-signup", data, config);
    }

    forgotPassword = function (data={
        username: ""
    }, config){
        return this.APIClient.post("/user/forgot-password", data, config);
    }

    confirmForgotPassword = function (data={
        username: "",
        newPassword: "",
        confirmationCode: "",
    }, config){
        return this.APIClient.post("/user/confirm-forgot-password", data, config);
    }

    changePasswordRequest = function(data={
        oldPass: "",
        newPass: "",
    }, config) {
        return this.APIClient.post('/user/changepassword', {...data,
            accessToken: getAuthInfo().AccessToken
        }, {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    getPublicUserDetail = function (data={
        targetUsername: ""
    }, config) {
        return this.APIClient.post("user/getpublicuserdetails", data, config);
    }

    getOwnUserDetail = function (data, config) {
        return this.APIClient.post('/user/getownuserdetails', {...data,
            accessToken: getAuthInfo().AccessToken
        },  {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        }); 
    }

    uploadImage = function (data={
        username: getAuthInfo().Username,
        purpose: "",
        fileName: "",
        content: "",
    }, config) {
        return this.APIClient.post("/user/upload-image", {
            username: getAuthInfo().Username,
            purpose: "",
            fileName: "",
            content: "",
            ...data
        },  {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    updateUserDetails = function (data={
        updateAttributes: {
            "picture": ""
        }
    }, config) {
        return this.APIClient.post('/user/updateuserdetails', {...data,
            accessToken: getAuthInfo().AccessToken
        },  {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    getRoomFilter = function (data, config)  {
        return this.APIClient.post('/room/get-filter-room-list', data,{...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }
    
    getGeneralTags = function (config) {
        return this.APIClient.get('/general/get-general-tag', {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    getLocationTags = function (config) {
        return this.APIClient.get('/general/get-location-tag', {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    getReviewList = function (data={
        roomID: ""
    }, config) {
        return this.APIClient.post('/review/get-reviews-for-a-room',
            {
                roomID: "",
                ...data
            },
            {...config,
                headers: {
                    Authorization: getAuthInfo().IdToken
                }
            }
        );
    }

    postReview = function (data={
        roomID: "",
        username: getAuthInfo().Username||"",
        userType: "Lessee",
        generalTag: [],
        locationTag: [],
        rating: 5,
        comment: "",
        pictures: [],
    }, config) {
        return this.APIClient.post('/review/user-review',{
                roomID: "",
                username: getAuthInfo().Username||"",
                userType: "Lessee",
                generalTag: [],
                locationTag: [],
                rating: 5,
                comment: "",
                pictures: [],
                ...data,
            },
            {...config,
                headers: {
                    Authorization: getAuthInfo().IdToken
                }
        });
    }

    getOneRoom = function (data={
        roomID: ""
    }, config) {
        return this.APIClient.post('/room/get-one-room',{
            roomID: "",
            ...data,
        },
        {...config,
            headers: {
                Authorization: getAuthInfo().IdToken
            }
        });
    }

    getClient = function(){
        return this.APIClient;
    }
}

let getAuthInfo = function(){
    try{
        return JSON.parse(localStorage.authInfo)||{};
    } catch(err){
        return {};
    }
}

let SlihomeAPIClient = new SlihomeAPI();

let cognitoClient = axios.create({
    baseURL: cognitoEndPoint,
    timeout: 30000,
});

const IMAGE_PURPOSE = {
    "AVATAR": "avatar",
    "ROOM": "room",
    "REVIEW": "review"
}

export default APIClient;

export { APIEndpoint, cognitoClientId, cognitoClientSecret, cognitoEndPoint, SlihomeAPI, SlihomeAPIClient, cognitoClient, IMAGE_PURPOSE};