/* eslint-disable import/no-anonymous-default-export */
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
    return (
        <Grid container justify="center" alignItems="center" style={{paddingTop: "5rem"}}>
            <Grid item>
                <img src="/icon/construction.svg" style={{width: "10rem"}} alt="construction icon"/>
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    Không tìm thấy trang bạn yêu cầu
                </Typography>
                <br/>
                <Typography variant="h5">
                    Nhấn vào <Link to="/" style={{color: "#ea285e"}}><u>đây</u></Link> để quay lại trang chủ
                </Typography>
            </Grid>
        </Grid>
    )
}