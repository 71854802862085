import React from 'react';
import './SHNewHomePage.css';
import { NavLink } from "react-router-dom";
import SHFooter from '../SHFooter/SHFooter';
import MessengerCustomerChat from "react-messenger-customer-chat";

const SHNewHomePage = () => {
    return(
        <div className="home-page">
            {/* <SlihomeHeader /> */}
            <MessengerCustomerChat
                pageId={process.env.REACT_APP_FB_PAGE_ID}
                appId={process.env.REACT_APP_FB_APP_ID}
            />
            <div className="banner">
                <div className="box-image">
                    <img src="/images/banner.png" className="image-banner" alt=""/>
                    <div className="content-banner">
                        <h1 className="title-banner">
                            NỀN TẢNG TÌM TRỌ THÔNG MINH VÀ ƯU VIỆT HÀNG ĐẦU
                            <NavLink to="/app" className="btn">
                               <img src="/images/icon_search.svg" style={{width: 120, marginTop: -54}} alt=""/>
                            </NavLink>
                        </h1>
                        <p>Nền tảng giúp các bạn tìm cho mình 1 ngôi nhà trọ phù hợp và ưng ý nhất
                        <br/>
                        Giao diện trực quan, 
                        thông tin chi tiết và xác thực đảm bảo thông tin nhà trọ được hiển thị chính xác tới người thuê.</p>
                        <div className="d-flex pt-3">
                            <button className="btn button-store">
                                <img src="/images/1.png" className="image-google" alt=""/>
                            </button>
                            <button className="btn button-store">
                                <img src="/images/AS.png" className="image-ios" alt=""/>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="item-main-content container pt-5">
                    <div className="line-title"></div>
                    <h2 className="title-main-content">Sli home có gì?</h2>
                    <div className="d-flex first-content">
                        <div className="col-sm-7 left-content p-0">
                            <img src="/images/Rectangle 496.png" alt=""/>
                        </div>
                        <div className="col-sm-5 right-content">
                            <h6 className="content-first">Các tính năng nổi bật, được xây dựng dựa trên khảo sát  về nhu cầu của người thuê</h6>
                            <div className="d-flex align-items-start">
                                <img src="/images/accuracy 1.svg" className="icon-benifit" alt=""/>
                                <div className="item-benifit">
                                    <p className="title-item">Thông tin xác thực</p>
                                    <p className="normal-content">Đảm bảo thông tin nhà trọ đầy đủ và chính xác nhất cho người thuê</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <img src="/images/nodes 1.svg" className="icon-benifit" alt=""/>
                                <div className="item-benifit">
                                    <p className="title-item">Kết nối trực tiếp</p>
                                    <p className="normal-content">Chat trực tiếp với chủ trọ thông qua nền tảng web/app</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <img src="/images/placeholder 1.svg" className="icon-benifit" alt=""/>
                                <div className="item-benifit">
                                    <p className="title-item">Định vị theo bản đồ</p>
                                    <p className="normal-content">Định vị chính xác vị trí nhà trọ ngay trên Maps</p>
                                </div>
                            </div>
                            <div className="w-100 text-center">
                                <NavLink to="/app" className="btn button-experience">Trải nghiệm ngay</NavLink>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="gray-background">
                    <div className="item-main-content pt-5 container">
                        <div className="line-title"></div>
                        <h2 className="title-main-content">Sli Home hoạt động như thế nào?</h2>
                        <h6 className="sub-title">Sli Home sẽ hỗ trợ bạn xuyên suốt quá trình thuê trọ.</h6>
                        <div className="d-flex list-cards">
                            <div className="col-sm-4 item-active">
                                <div className="box-image-active">
                                    <img className="image-active-item" src="images/Rectangle 497.png" alt=""/>
                                    <p className="title-item">Tìm nhà trọ</p>
                                    <p className="normal-content">Sli Home sẽ giúp bạn tìm được nhà trọ ưng ý và phù hợp trong thời gian ngắn nhất</p>
                                </div>
                            </div>
                            <div className="col-sm-4 item-active">
                                <div className="box-image-active">
                                    <img className="image-active-item" src="images/Rectangle 499.png" alt=""/>
                                    <p className="title-item">Theo dõi tiền thuê</p>
                                    <p className="normal-content">Các thông tin về tiền thuê, tiền dịch vụ,... sẽ được cập nhật liên tục đến người thuê.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 item-active">
                                <div className="box-image-active">
                                    <img className="image-active-item" src="images/Rectangle 498.png" alt=""/>
                                    <p className="title-item">Tiện ích thanh toán</p>
                                    <p className="normal-content">Vô vàn ưu đãi cho người thuê khi thanh toán trực tiếp qua app.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="item-main-content container">
                    <h4 className="title-bottom-item">Được xây dựng và phát triển từ những trải nghiệm thực tế khi đi thuê trọ của các thành viên Sli Home. Chúng tôi luôn cố gáng để tạo ra 1 sản phẩm tốt nhất dành riêng cho các bạn đang đi tìm, thuê trọ. </h4>
                    <div className="box-image">
                        <img src="/images/Rectangle 501.png" className="image-bottom" alt=""/>
                    </div>
                </div>
            </div>
            <SHFooter/>
        </div>
    )
}

export default SHNewHomePage;