import React from 'react';
import clsx from 'clsx';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Divider, Drawer, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SlihomeTheme from '../Models/SlihomeMuiTheme';
import { useLocation } from 'react-router';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SlihomeHeaderHiddenNav() {
    const classes = useStyles();
    let location = useLocation();
    const [state, setState] = React.useState({
      left: false,
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };

    const navList = [
      {
        url: "/",
        text: "Giới thiệu"
      },
      {
        url: "/app",
        text: "Tìm nhà"
      },
      {
        url: "/cam-nang",
        text: "Cẩm nang"
      },
      {
        url: "/dieu-khoan",
        text: "Điều khoản"
      },
    ]
  
    const list = (anchor) => (
      <MuiThemeProvider theme={SlihomeTheme}>
        <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
            {navList.map((nav, index) => (
              <ListItem component="a" href={nav.url} key={nav.text} selected={location.pathname === nav.url}>
                <ListItemText primary={nav.text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem component="a" href="https://host.slihome.com">
              <ListItemText primary="Dành cho chủ trọ" />
            </ListItem>
          </List>
        </div>
      </MuiThemeProvider>
    );
    let anchor = "left"
    return (
      <MuiThemeProvider theme={SlihomeTheme}>
          <React.Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}><MenuRoundedIcon color="primary"/></IconButton>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
      </MuiThemeProvider>
    );
  }